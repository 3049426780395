import { createStore } from 'devextreme-aspnet-data-nojquery';
import { useState, useCallback } from 'react';
import config from '../config';
import { useScreenSize } from './media-query';
import truncate from 'truncate-html'

export function groupBy(arr, property) {
  return arr.reduce(function(memo, x) {
    if (!memo[x[property]]) { memo[x[property]] = []; }
    memo[x[property]].push(x);
    return memo;
  }, {});
}

export function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}
export function truncatedHtml(content, maxLength = 80) {
  if (!content || content.length <= maxLength) {
    return content;
  }
  const truncatedContent = truncate(content, maxLength, { byWords: true });
  return truncatedContent;
}

export function proxyPathImg(path) {
  if (!path) {
    return "/dummy.png";
  }
  return `${window.env.REACT_APP_API_URL}/Public/Proxy?url=${encodeURIComponent(path)}`;
}


export function pathImg(path) {
  if (!path) {
    return "/dummy.png";
  }
  if (path.startsWith("http")) {
    return path;
  }
  return `${window.env.REACT_APP_UPLOAD_URL}/${path}`;
}
export function pathImgBlank(path) {
  if (!path) {
    return "";
  }
  if (path.startsWith("http")) {
    return path;
  }
  return `${window.env.REACT_APP_UPLOAD_URL}/${path}`;
}

export function pathFile(path) {
  if (path.startsWith("http")) {
    return path;
  }
  return `${window.env.REACT_APP_UPLOAD_URL}/${path}`;
}

export async function request(path, options = { method: 'GET' }) {
  const response2 = await fetch(`${window.env.REACT_APP_API_URL}/${path}`, {
    ...options,
  });
  if (response2.status != 200) {
    return null;
  }
  var data = await response2.json();
  return data;
}

export function listToTree(list, keyId, keyParentId) {
  var map = {}, node, roots = [], i;
  for (i = 0; i < list.length; i++) {
    map[list[i][keyId]] = i;
    list[i].children = [];
  }
  for (i = 0; i < list.length; i++) {
    node = list[i];
    if (node[keyParentId] != null && node[keyParentId] != 0) {
      if (list[map[node[keyParentId]]] != undefined) {
        list[map[node[keyParentId]]].children.push(node);
      }
      continue;
    }
    roots.push(node);
  }
  return roots;
}
export function listToTreeWithRoot(list, keyId, keyParentId, rootId) {
  var map = {}, node, roots = [], i;
  for (i = 0; i < list.length; i++) {
    map[list[i][keyId]] = i;
    list[i].children = [];
  }
  for (i = 0; i < list.length; i++) {
    node = list[i];
    if (node[keyParentId] == rootId) {
      roots.push(node);
      continue;
    }
    if (list[map[node[keyParentId]]] != undefined) {
      list[map[node[keyParentId]]].children.push(node);
    }
  }
  return roots;
}

export const interleave = (arr, thing) => [].concat(...arr.map(n => [n, thing])).slice(0, -1);