
import React, { useState, useCallback, useRef, useEffect } from 'react';

import config from '../config';
import { createCustomStore, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxRelate from '../components/Post/BoxRelate';
import { useParams } from 'react-router-dom';
import LayoutHome from '../layouts/LayoutHome';
import { Footer, Header } from '../components';
import Emagazine from './Emagazine';
import ChuyenMucCap1Layout from './ChuyenMucCap1Layout';
import ChuyenMucCap2Layout from './ChuyenMucCap2Layout';
import ChuyenMucCap3Layout from './ChuyenMucCap3Layout';
import ChuyenMucTongHopLayout from './ChuyenMucTongHopLayout';
import Breadcrumb from '../components/Common/BreadCrumb';
import { Helmet } from 'react-helmet';


export default function ChuyenMuc() {
    const params = useParams();
    const [chuyenmuc, setChuyenmuc] = useState();
    useEffect(() => {
        (async function () {
            const vbs = await request(`Public/ChuyenMucDetail?ID=${params.id}`);
            setChuyenmuc(vbs);
        })();
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI-${chuyenmuc && chuyenmuc.data.TenChuyenMuc}`}</title>
            </Helmet>
            {chuyenmuc && chuyenmuc.data.Type == 3 &&
                <Emagazine chuyenmuc={chuyenmuc} />
            }
            {chuyenmuc && chuyenmuc.data.Type == 4 &&
                <>
                    <Header
                        menuToggleEnabled
                    />
                    <div className={"main-wrapper"}>
                        <div className="container">
                            <Breadcrumb>
                                <a href="/" className="breadcrumb-item">Trang chủ</a>
                                <a href="/tintuc" className="breadcrumb-item">Tin Tức</a>
                                {chuyenmuc && chuyenmuc.parents &&
                                    chuyenmuc.parents.map((item, index) => {
                                        if (index == 0) {
                                            return;
                                        }
                                        return (
                                            <a href={`/chuyenmuc/${item.ID}`} className="breadcrumb-item">{item.TenChuyenMuc}</a>
                                        )
                                    }
                                    )
                                }
                            </Breadcrumb>
                            <h1 className="title-main main bold f18 mb-4 text-uppercase">{chuyenmuc && chuyenmuc.data.TenChuyenMuc}</h1>
                            <ChuyenMucTongHopLayout chuyenmuc={chuyenmuc} />
                        </div>
                    </div>
                    <Footer />
                </>
            }
            {chuyenmuc && chuyenmuc.data.Type == 5 &&
                <>
                    <Header
                        menuToggleEnabled
                    />
                    <div className={"main-wrapper"}>
                        <div className="container">
                            <Breadcrumb>
                                <a href="/" className="breadcrumb-item">Trang chủ</a>
                                {chuyenmuc && chuyenmuc.parents &&
                                    chuyenmuc.parents.map((item, index) => {
                                        if (index == 0) {
                                            return;
                                        }
                                        return (
                                            <a href={`/chuyenmuc/${item.ID}`} className="breadcrumb-item">{item.TenChuyenMuc}</a>
                                        )
                                    }
                                    )
                                }
                            </Breadcrumb>
                            <h1 className="title-main main bold f18 mb-4 text-uppercase">{chuyenmuc && chuyenmuc.data.TenChuyenMuc}</h1>
                            <ChuyenMucCap3Layout chuyenmuc={chuyenmuc} showsidebar={false} />
                        </div>
                    </div>
                    <Footer />
                </>
            }
            {chuyenmuc && [3, 4, 5].indexOf(chuyenmuc.data.Type) == -1 &&
                <>
                    <Header
                        menuToggleEnabled
                    />
                    <div className={"main-wrapper"}>
                        <div className="container">
                            <Breadcrumb>
                                <a href="/" className="breadcrumb-item">Trang chủ</a>
                                <a href="/tintuc" className="breadcrumb-item">Tin Tức</a>
                                {chuyenmuc && chuyenmuc.parents &&
                                    chuyenmuc.parents.map((item, index) => {
                                        if (index == 0) {
                                            return;
                                        }
                                        return (
                                            <a href={`/chuyenmuc/${item.ID}`} className="breadcrumb-item">{item.TenChuyenMuc}</a>
                                        )
                                    }
                                    )
                                }
                            </Breadcrumb>
                            <h1 className="title-main main bold f18 mb-4 text-uppercase">{chuyenmuc && chuyenmuc.data.TenChuyenMuc}</h1>
                            {chuyenmuc && chuyenmuc.parents.length == 1 &&
                                <ChuyenMucCap1Layout chuyenmuc={chuyenmuc} />
                            }
                            {chuyenmuc && chuyenmuc.parents.length == 2 &&
                                <ChuyenMucCap2Layout chuyenmuc={chuyenmuc} />
                            }
                            {chuyenmuc && chuyenmuc.parents.length != 1 && chuyenmuc.parents.length != 2 &&
                                <ChuyenMucCap3Layout chuyenmuc={chuyenmuc} />
                            }
                        </div>
                    </div>
                    <Footer />

                </>
            }
        </>
    );
}
