
import React, { useState, useCallback, useRef } from 'react';

import config from '../config';
import { createCustomStore, pathImg, request, truncatedHtml } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxRelate from '../components/Post/BoxRelate';
import { useParams } from 'react-router-dom';
import LayoutHome from '../layouts/LayoutHome';
import BoxSideBar from '../components/Common/BoxSideBar';
import BoxNew from '../components/Common/BoxNew';
import BoxSideBarVBCDDH from '../components/Common/BoxSideBarVBCDDH';
import BoxSideBarMenu from '../components/Common/BoxSideBarMenu';

export default function ChuyenMucCap2Layout({ chuyenmuc }) {
    return (
        <>
            <div className="htvb-box">
                <BoxSideBarMenu idMenu={1746} showChild={true} />
                <div className="box-content">
                    <div className="top-news-box">
                        <div className="top-news">
                            {chuyenmuc.topposts.length > 0 &&
                                <>
                                    <div className="hotnews">
                                        <a className="post-image">
                                            <img src={pathImg(chuyenmuc.topposts[0].AnhDaiDienUrl)} alt={chuyenmuc.topposts[0].AnhDaiDienUrl} />
                                        </a>
                                        <h2 className="post-title">
                                            <a href={`/baiviet/${chuyenmuc.topposts[0].ID}`}>{chuyenmuc.topposts[0].TieuDe}</a>
                                        </h2>
                                        <p className="post-desc" dangerouslySetInnerHTML={{ __html: truncatedHtml(chuyenmuc.topposts[0].MoTa) }}></p>
                                    </div>
                                </>
                            }
                            <div className="list-news-hot">
                                {chuyenmuc.topposts.map((item, index) => {
                                    if (index == 0) {
                                        return;
                                    }
                                    return (
                                        <div className="item">
                                            <a href={`/baiviet/${item.ID}`} className="image">
                                                <img src={pathImg(item.AnhDaiDienUrl)} alt={item.AnhDaiDienUrl} />
                                            </a>
                                            <a className="text-content" href={`/baiviet/${item.ID}`}>
                                                {truncatedHtml(item.TieuDe, 20)}
                                            </a>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                {chuyenmuc.childs.map((item) =>
                    <BoxNew idChuyenmuc={item.ID} limit={2} excludes={chuyenmuc.topposts.map(x => x.ID)} className="col-md-4 col-sm-6 col-12" />
                )}
            </div>
        </>
    );
}
