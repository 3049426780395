
import React, { useState, useCallback, useRef, useEffect } from 'react';

import config from '../config';
import { createCustomStore, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { Redirect } from 'react-router-dom';

import { Footer, Header } from '../components';
import HTMLReactParser from 'html-react-parser';
import { Helmet } from 'react-helmet';
import TinMacDinhLayout from './TinMacDinhLayout';
import TinEmagazineLayout from './TinEmagazineLayout';
import TinSidebarTinTucLayout from './TinSidebarTinTucLayout';


export default function BaiViet() {
    const params = useParams();
    const [post, setPost] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        (async function () {
            const data = await request(`Public/PostDetail?ID=${params.id}&lang=vi`);
            if (data.data == null) {
                navigate("/");
                return;
            }
        
            setPost(data);
        })();
    }, [])
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI-${post && post.data.TieuDe}`}</title>
            </Helmet>
            {post && post.data.ChuyenMuc && post.data.ChuyenMuc.Type == 3 && 
                <TinEmagazineLayout post={post} />
            }
            {post && post.data.ChuyenMuc && post.data.ChuyenMuc.Type == 5 && 
                <TinSidebarTinTucLayout post={post} />
            }

            {post && post.data.ChuyenMuc && [3, 5].indexOf(post.data.ChuyenMuc.Type) == -1 &&
                <TinMacDinhLayout post={post}/>
            }
        </>
    );
}
