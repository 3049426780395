
import React, { useState, useCallback, useRef, useEffect } from 'react';

import Moment from 'react-moment';
import config from '../config';
import { createCustomStore, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxRelate from '../components/Post/BoxRelate';
import { useParams } from 'react-router-dom';
import LayoutHome from '../layouts/LayoutHome';
import BoxSideBar from '../components/Common/BoxSideBar';
import ReactPaginate from 'react-paginate';


export default function Page404En({ chuyenmuc }) {
    const params = useParams();
    return (
        <>
            <div className="breadcrumb">
                <a href="/" className="breadcrumb-item">Home</a>
            </div>
            <div className="row">
                <div className="main-left col-sm-12 col-12">
                    <h1 className="title">Not found: 404</h1>
                </div>
            </div>
        </>
    );
}
