import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import ChuongTrinhDuAnDeAn from './pages/ChuongTrinhDuAnDeAn';
import ThiTruongLaoDong from './pages/ThiTruongLaoDong';
import MediaNoiBat from './pages/MediaNoiBat';
import VanBanQuocTe from './pages/VanBanQuocTe';
import VanBanChiDaoDieuHanh from './pages/VanBanChiDaoDieuHanh';
import VanBanQPPL from './pages/VanBanQPPL';
import ThoiKyPhatTrien from './pages/ThoiKyPhatTrien';
import LanhDaoBo from './pages/LanhDaoBo';
import CoCauToChuc from './pages/CoCauToChuc';
import ChucNangNhiemVu from './pages/ChucNangNhiemVu';
import CoCauToChucChiTiet from './pages/CoCauToChucChiTiet';
import TimKiem from './pages/TimKiem';
import SiteMap from './pages/SiteMap';
import TinTuc from './pages/TinTuc';
import DanhMucThongTin from './pages/DanhMucThongTin';
import HomeTest from './pages/HomeTest';
import LayoutHome from './layouts/LayoutHome';
import SuKienHoiThao from './pages/SuKienHoiThao';
import DuThaoVanBan from './pages/DuThaoVanBan';
import NhanSuMoi from './pages/NhanSuMoi';
import TrangThanhPhan from './pages/TrangThanhPhan';
import HomeEn from './pages/HomeEn';
import TinTucEn from './pages/TinTucEn';
import ChucNangNhiemVuEn from './pages/ChucNangNhiemVuEn';
import LanhDaoBoEn from './pages/LanhDaoBoEn';
import CoCauToChucEn from './pages/CoCauToChucEn';
import VanBanChiDaoDieuHanhEn from './pages/VanBanChiDaoDieuHanhEn';
import LayoutHomeEn from './layouts/LayoutHomeEn';
import CoCauToChucChiTietEn from './pages/CoCauToChucChiTietEn';
import ChuyenMucEn from './pages/ChuyenMucEn';
import ChuyenMuc from './pages/ChuyenMuc';
import BaiViet from './pages/BaiViet';
import BaiVietEn from './pages/BaiVietEn';
import TimKiemEn from './pages/TimKiemEn';
import VanBanQPPLEn from './pages/VanBanQPPLEn';
import TrangThanhPhanEn from './pages/TrangThanhPhanEn';
import TrangChu from './pages/TrangChu';
import SiteMapEn from './pages/SiteMapEn';
import SuKienHoiThaoEn from './pages/SuKienHoiThaoEn';
import Page404 from './pages/Page404';
import Page404En from './pages/Page404En';
import VanBanQuocTeEn from './pages/VanBanQuocTeEn';

export default function Content() {
  return (
    <Routes>

      <Route element={<LayoutHome />}>
        <Route path={'/tintuc'} element={<TinTuc />} />
        <Route path={'/timkiem/:keyword'} element={<TimKiem />} />
        <Route path={'/thitruonglaodong/:id?'} element={<ThiTruongLaoDong />} />
        <Route path={'/medianoibat/:id?'} element={<MediaNoiBat />} />
        <Route path={'/sukienhoithao/:id?'} element={<SuKienHoiThao />} />
        <Route path={'/danhmucthongtin/:id?'} element={<DanhMucThongTin />} />
        <Route path={'/thoikyphattrien/:id?'} element={<ThoiKyPhatTrien />} />
        <Route path={'/lanhdaobo/:id?'} element={<LanhDaoBo />} />
        <Route path={'/cocautochuc/'} element={<CoCauToChuc />} />
        <Route path={'/cocautochuc/:id?/:idlanhdao?'} element={<CoCauToChucChiTiet />} />
        <Route path={'/chucnangnhiemvu'} element={<ChucNangNhiemVu />} />
        <Route path={'/nhansumoi/'} element={<NhanSuMoi />} />
        <Route path={'/trangthanhphan/'} element={<TrangThanhPhan />} />
        <Route path={'/khongtimthay'} element={<Page404 />} />

        <Route path={'/hometest'} element={<HomeTest />} />
      </Route>
      <Route element={<LayoutHomeEn />}>
        <Route path={'/news'} element={<TinTucEn />} />
        <Route path={'/search/:keyword'} element={<TimKiemEn />} />
        <Route path={'/dutiesresponsibilities'} element={<ChucNangNhiemVuEn />} />
        <Route path={'/leadership/:id?'} element={<LanhDaoBoEn />} />
        <Route path={'/organizational/'} element={<CoCauToChucEn />} />
        <Route path={'/organizational/:id?/:idlanhdao?'} element={<CoCauToChucChiTietEn />} />
        <Route path={'/subpage/'} element={<TrangThanhPhanEn />} />
        <Route path={'/event/:id?'} element={<SuKienHoiThaoEn />} />
        <Route path={'/notfound'} element={<Page404En />} />
      </Route>

      <Route key={'/'} path={'/'} element={
        <TrangChu />
      } />


      <Route path={'/chuyenmuc/:id/:layout?'} element={<ChuyenMuc />} />
      <Route path={'/vanbanqppl/:keyword?'} element={<VanBanQPPL />} />
      <Route path={'/legislation/:keyword?'} element={<VanBanQPPLEn />} />
      <Route path={'/baiviet/:id'} element={<BaiViet />} />

      <Route path={'/category/:id/:layout?'} element={<ChuyenMucEn />} />
      <Route path={'/topic/:id'} element={<BaiVietEn />} />

      <Route element={<LayoutHome className={`section vanphamphapquy quan-ly-du-an`} />}>
        <Route path={'/duthaovanban/:id?'} element={<DuThaoVanBan />} />
      </Route>
      <Route element={<LayoutHome className={`section phan-anh-kien-nghi`} />}>
        <Route path={'/duan/:id?'} element={<ChuongTrinhDuAnDeAn />} />
        <Route path={'/sodosite/'} element={<SiteMap />} />
      </Route>
      <Route element={<LayoutHomeEn className={`section phan-anh-kien-nghi`} />}>
        <Route path={'/sitemap/'} element={<SiteMapEn />} />
      </Route>
      <Route element={<LayoutHome className={`section section-document`} />}>
        <Route path={'/vanbanquocte/:id?'} element={<VanBanQuocTe />} />
      </Route>
      <Route element={<LayoutHomeEn className={`section section-document`} />}>
        <Route path={'/internationaldocuments/:id?'} element={<VanBanQuocTeEn />} />
      </Route>

      <Route element={<LayoutHomeEn className={`section vanphamphapquy quan-ly-du-an`} />}>
        <Route path={'/official/:id?'} element={<VanBanChiDaoDieuHanhEn />} />
      </Route>
      <Route element={<LayoutHome className={`section vanphamphapquy quan-ly-du-an`} />}>
        <Route path={'/vanbanchidao/:id?'} element={<VanBanChiDaoDieuHanh />} />
      </Route>

      <Route
        path='*'
        element={<Navigate to='/' />}
      />
    </Routes>
  );
}
