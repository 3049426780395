
import ScrollView from 'devextreme-react/scroll-view';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Header, Footer } from '../components';
import { useScreenSize } from '../utils/media-query';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../utils/patches';
import { LoadPanel } from 'devextreme-react';
import { Outlet } from "react-router-dom"
import HeaderEn from '../components/Layout/HeaderEn';
import FooterEn from '../components/Layout/FooterEn';

export default function LayoutHomeEn({ title, loading, children, className="main-wrapper" }) {
    return (
        <>
            <HeaderEn
                menuToggleEnabled
            />
            <div className={className}>
              <div className="container">
                <Outlet /> 
              </div>
            </div>
            <FooterEn />
        </>
    );
}
