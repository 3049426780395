import React, { useState } from 'react';


const CheckBox = ({ title, checked, onClick }) => {
	return (
		<li className={`${checked?'active':''}`}>
			<a onClick={onClick}>
				<span className="check" /> {title}
			</a>
		</li>
	);
}

export default CheckBox;