
import React, { useState, useCallback, useRef, useEffect } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import config from '../config';
import { createCustomStore, pathFile, pathImg, proxyPathImg, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import BoxSideBarVBCDDH from '../components/Common/BoxSideBarVBCDDH';
import Pagination from "react-js-pagination";
import { useAuth } from '../contexts/auth';

export default function MediaNoiBat() {
  const { t } = useTranslation();
  const [items, setItems] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [title, setTitle] = useState();
  const type = searchParams.get("type");
  const auth = useAuth();
  const id = params.id;
  useEffect(() => {
    if (type == "video") {
      setTitle("Video nổi bật");
    } else if (type == "audio") {
      setTitle("Audio nổi bật");
    } else if (type == "anh") {
      setTitle("Album nổi bật");
    }
    (async function () {
      const data2 = await request(`Public/MediaNoiBat?type=${type}&skip=0&take=${itemsPerPage}&requireTotalCount=true`);
      setTotalCount(data2.totalCount);
      setItems(data2);
    })();
  }, [])
  // Invoke when user click to request another page.
  const handlePageClick = async (pageNumber) => {
    const newOffset = ((pageNumber - 1) * itemsPerPage) % totalCount;
    const data2 = await request(`Public/MediaNoiBat?type=${type}&skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true`);
    setActivePage(pageNumber);
    setTotalCount(data2.totalCount);
    setItems(data2);
  };

  return (
    <>
      <div className="breadcrumb">
        <a href="/" className="breadcrumb-item">Trang chủ</a>
        <a href="/medianoibat" class="breadcrumb-item">{title}</a>
      </div>
      <div className="row">
        <div className="col-sm-8 col-12">
          <div className="list-tt-c3">
            {items && items.data.map((item, index) =>
              <div className="item">
                <div className="text-content">
                  <h2 className="post-title">
                    <a href={`${item.LienKet}`}>{item.TieuDe}</a>
                  </h2>
                </div>
                <a href={`${item.LienKet}`} className="post-image">
                  <img src={proxyPathImg(item.LinkAnhDaiDien)} alt={item.LinkAnhDaiDien} />
                </a>
              </div>
            )
            }
          </div>

          <div className="navi-page">
            <Pagination
              activePage={activePage}
              itemClass='page-item'
              linkClass='page-link'
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={totalCount}
              pageRangeDisplayed={5}
              onChange={handlePageClick}
            />
          </div>
        </div>
        <div className="col-sm-4 col-xs-12">
          <div className="box-sidebar">
            <div className="sidebar-cat">
              <div className="title">ĐA PHƯƠNG TIỆN</div>
              <div className="menus">
                <a href={`/medianoibat?type=video`}>Video</a>
                <a href={`/medianoibat?type=audio`}>Audio</a>
                <a href={`/medianoibat?type=anh`}>Album</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
