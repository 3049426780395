import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import config from '../../config';
import { useAuth } from '../../contexts/auth';
import { pathImg, request, truncatedHtml } from '../../utils/api';
import { Outlet, Link } from "react-router-dom";
import Moment from 'react-moment';
import 'moment/locale/vi';
import parse from 'html-react-parser';
import moment from 'moment';


export default function BoxTinTongHop({ hots }) {
  const auth = useAuth();
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
  };
  return (
    <>
      <div className="top-news-box">
        <div className="top-news">
          {hots && hots.top &&
            <div className="hotnews">
              <a className="post-image">
                <img src={pathImg(hots.top.AnhDaiDienUrl)} alt={hots.top.AnhDaiDienUrl} />
              </a>
              <h2 className="post-title">
                <a href={`/baiviet/${hots.top.ID}`}>{hots.top.TieuDe}</a>
              </h2>
              <p className="post-desc">
                {parse(truncatedHtml(hots.top.MoTa))}
              </p>
            </div>
          }
          <div className="list-news-hot">
            {/* <h3 className="main-subtitle mb-0">
              <a href="#">Tin xem nhiều</a>
            </h3> */}
            {hots && hots.data.map((item, index) => {
              return (
                <div className="item">
                  <a href={`/baiviet/${item.ID}`} className="image">
                    <img src={pathImg(item.AnhDaiDienUrl)} alt={item.AnhDaiDienUrl} />
                  </a>
                  <a className="text-content" href={`/baiviet/${item.ID}`}>
                    {truncatedHtml(item.TieuDe, 20)}
                  </a>
                </div>
              )
            }
            )}
          </div>
        </div>
      </div>
    </>
  )
}
