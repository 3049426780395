
import React, { useState, useCallback, useRef, useEffect } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import config from '../config';
import { createCustomStore, pathFile, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BoxSideBarVBCDDH from '../components/Common/BoxSideBarVBCDDH';
import { useAuth } from '../contexts/auth';
import Pagination from "react-js-pagination";

export default function ThiTruongLaoDong() {
  const { t } = useTranslation();
  const [coquans, setCoquans] = useState();
  const [thitruongs, setThitruongs] = useState();
  const [coquan, setCoquan] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const params = useParams();
  const auth = useAuth();
  const id = params.id;
  useEffect(() => {
    (async function () {
      const data2 = await request(`Public/ThiTruongLaoDong?skip=0&take=${itemsPerPage}&requireTotalCount=true`);
      setTotalCount(data2.totalCount);
      setThitruongs(data2);
      const data = await request(`Public/CoQuanBanHanh?lang=${auth.language}`);
      setCoquans(data);
      if (id) {
        const bans = await request(`/Public/CoQuanBanHanhDetail?ID=${id}`);
        setCoquan(bans);
      }
    })();
  }, [])
  // Invoke when user click to request another page.
  const handlePageClick = async (pageNumber) => {
    const newOffset = ((pageNumber-1) * itemsPerPage) % totalCount;
    const data2 = await request(`Public/ThiTruongLaoDong?skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true`);
    setActivePage(pageNumber);
    setTotalCount(data2.totalCount);
    setThitruongs(data2);
  };
  return (
    <>
      <div className="breadcrumb">
        <a href="/" className="breadcrumb-item">Trang chủ</a>
        <a href="/thitruonglaodong" class="breadcrumb-item">Bản tin thị trường lao động</a>
      </div>
      <div className="row">
        <div className="col-sm-8 col-12">
          <div className="thi-truong-lao-dong">
            <div className="row">

              {thitruongs && thitruongs.data.map((item, index) =>
                <div className="col-sm-3 col-12">
                  <div className="item">
                    {item.ThiTruongLaoDong_Files.map((aFile) => 
                      <>
                        <a href={pathFile(aFile.File.LinkLuuTru)} title={item.TieuDe} className="img-bg" style={{ backgroundImage: `url("${window.env.REACT_APP_UPLOAD_URL}/${item.AnhDaiDien}")` }} />
                        <a className="title" href={pathFile(aFile.File.LinkLuuTru)}>{item.TieuDe}</a>
                      </>
                    )}
                  </div>
                </div>
              )
              }
            </div>
          </div>
          <div className="navi-page">
                <Pagination
                        activePage={activePage}
                        itemClass='page-item'
                        linkClass='page-link'
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={5}
                        onChange={handlePageClick}
                    />
          </div>
        </div>
        <div className="col-sm-4 col-xs-12">
          <div className="box-sidebar -menus">
            <div className="head">CƠ QUAN BAN HÀNH</div>
            <div className="body">
              <ul className="list-news">
                {coquans && coquans.data.map((item, index) =>
                  <li>
                    <a href={`/thitruonglaodong/${item.ID}`} className="link">{item.TenCoQuanBanHanh}</a>
                  </li>
                )
                }
              </ul>
            </div>
          </div>
          <BoxSideBarVBCDDH id={104} />
        </div>
      </div>
      {coquan &&
        <>
          {coquan.data.HoTenLanhDao}
          <div dangerouslySetInnerHTML={{ __html: coquan.data.TieuSuBanThan }} />
        </>
      }
    </>
  );
}
