import React, { useState, useCallback, useRef, useEffect } from 'react';
import { request } from '../../utils/api';

export default function BoxSideBarMenu({idMenu, showChild=false}) {
  const [menu, setMenu] = useState();
  useEffect(() => {
      (async function () {
          const vbs = await request(`Public/MenuDetail?ID=${idMenu}`);
          setMenu(vbs);
      })();
  }, [])
  return (
    <>
      {menu && 
        <div className="box-sidebar">
            <div className="sidebar-cat">
                {menu && menu.data &&
                  <div className="title">{menu.data.TenMenu}</div>
                }
                <div className="menus">
                  {menu && showChild && menu.childs.map((item) => 
                    <a href={`${item.URL}`} className={`${item.URL == window.location.pathname?"active":""}`}>{item.TenMenu}</a>
                  )}
                </div>
            </div>
        </div>
      }
    </>
  )
}
