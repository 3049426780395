import React from 'react';

export default function Footer({ ...rest }) {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-info">
          <div className="box-left box-info">
            <div className="title">© CỔNG THÔNG TIN ĐIỆN TỬ BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI</div>
            <div className="info">
              <span className="icon isax-location1" />
              <span className="text-m">Địa chỉ: 12 Ngô Quyền, Hoàn Kiếm, Hà Nội</span>
            </div>
            <div className="row">
              <div className="col-sm-6 col-12">
                <div className="info">
                  <span className="icon isax-call1" />
                  <span className="text-m">(024) 62703645</span>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="info">
                  <span className="icon isax-sms1" />
                  <span className="text-m">banbientap@molisa.gov.vn</span>
                </div>
              </div>
            </div>
            <div className="info">
              <span className="icon isax-building1" />
              <span className="text-m">Đơn vị quản lý &amp; vận hành: Trung tâm Công nghệ Thông tin</span>
            </div>
            <div className="ncsc">
              <a href="https://tinnhiemmang.vn/danh-ba-tin-nhiem/molisagovvn-1624937765">
                <img src="https://tinnhiemmang.vn/handle_cert?id=molisa.gov.vn" alt="https://tinnhiemmang.vn/handle_cert?id=molisa.gov.vn" />
              </a>
              &nbsp;
              <a href={`https://api.qrserver.com/v1/create-qr-code/?size=60x60&data=${window.location.href}`}>
                <img src={`https://api.qrserver.com/v1/create-qr-code/?size=60x60&data=${window.location.href}`} alt="ncsc.png" />
              </a>
            </div>
          </div>
          <div className="box-right box-info">
            <div className="title">Ý kiến phản ánh, kiến nghị của tổ chức, cá nhân về quy định hành chính của Bộ</div>
            <div className="desc">
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="info">
                    <span className="icon isax-call1" />
                    <span className="text-m">(024) 39388407</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="info">
                    <span className="icon isax-sms1" />
                    <span className="text-m">tiepnhanykien@molisa.gov.vn</span>
                  </div>
                </div>
              </div>
              <div className="info">
                <span className="icon isax-frame-41" />
                <span className="text-m">Giấy phép: Số 27/GP-BC ngày 27/3/2005</span>
              </div>
              <div className="info">
                <span className="text-m">Ghi rõ nguồn "Cổng Thông tin điện tử Bộ LĐTBXH" hoặc http://www.molisa.gov.vn
                  khi phát hành lại thông tin từ nguồn này.</span>
              </div>
            </div>
            <div className="list-social">
              <a href="#">
                <img src="/assets/images/social/zalo.png" alt="/assets/images/social/zalo.png" />
              </a>
              <a href="#">
                <img src="/assets/images/social/fb.png" alt="/assets/images/social/fb.png" />
              </a>
              <a href="#">
                <img src="/assets/images/social/yt.png" alt="/assets/images/social/yt.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
