
import React, { useState, useCallback, useRef, useEffect } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import config from '../config';
import { createCustomStore, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxHot from '../components/Home/BoxHot';
import BoxVBPL from '../components/Home/BoxVBPL';
import BoxNDDN from '../components/Home/BoxNDDN';
import BoxNews from '../components/Home/BoxNews';
import BoxMedia from '../components/Home/BoxMedia';
import BoxPartners from '../components/Home/BoxPartners';
import Slider from 'react-slick';
import BoxSlider from '../components/Home/BoxBanner';
import loadable from "@loadable/component";
import { LoadPanel } from 'devextreme-react';


const LoadableComponent = loadable((props) => import(`../components/Home/${props.component}`), {
  fallback: <LoadPanel visible={true} />,
  cacheKey: (props) => props.component
});

export default function HomeTest() {
  const [code, setCode] = useState();
  useEffect(() => {
    (async function () {
      const data = await request(`Public/BlockConfig?ID=1`);
      if (data) {
        setCode(JSON.parse(data.data.Code));
      }
    })();
  }, [])
  const renderItem = (item) => {
    if (item && item.type == "row") {
      return (
        <>
          <div className={`${item.class}`}>
            {item.children && item.children.map((aChild) => {
              return renderItem(aChild)
            })}
          </div>
        </>
      )
    }
    if (item && item.type == "column") {
      return (
        <>
          <div className={item.class}>
            {item.children && item.children.map((aChild) => {
              return renderItem(aChild)
            })}
          </div>
        </>
      )
    }
    if (item && item.type == "component") {
      if(!item.Path && item.Code){
        return (
              <div dangerouslySetInnerHTML={{ __html: item.Code }}></div>
        )
      }
      let config = {};
      if(item.Config){
        config = JSON.parse(item.Config);
      }
      return (
        <LoadableComponent component={item.Path} {...config} />
      )
    }
    return (<></>);
  }
  return (
    <>
      {code && code.map((item, index) => {
        return renderItem(item);
      })
      }
    </>
  );
}
