
import React, { useState, useCallback, useRef, useEffect } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import config from '../config';
import { createCustomStore, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxHot from '../components/Home/BoxHot';
import BoxVBPL from '../components/Home/BoxVBPL';
import BoxNDDN from '../components/Home/BoxNDDN';
import BoxMedia from '../components/Home/BoxMedia';
import BoxPartners from '../components/Home/BoxPartners';
import BoxBanner from '../components/Home/BoxBanner';
import BoxNotification from '../components/Home/BoxNotification';
import { Footer, Header } from '../components';
import { Helmet } from "react-helmet";

export default function Home() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI-Trang chủ`}</title>
      </Helmet>
      <Header
        menuToggleEnabled
      />
      <div className={"main-wrapper"}>
        <div className="container">
          <BoxHot />
          <BoxNotification />
          <BoxBanner idViTri={window.env.REACT_APP_SLIDE_BANNER_TRANG_CHU_TIENG_VIET}/>
          <BoxVBPL />
          <BoxNDDN />
          <BoxMedia />
          <BoxPartners />
        </div>
      </div>
      <Footer />
    </>
  );
}
