
import React, { useState, useEffect } from 'react';

import config from '../config';
import { pathImg, request, truncatedHtml } from '../utils/api';
import { useNavigate, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { LoadPanel } from 'devextreme-react';
import { Footer } from '../components';

export default function Emagazine({ chuyenmuc }) {
  const params = useParams();

  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [pageCount, setPageCount] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [items, setItems] = useState([]);
  const [emagazines, setEmagazines] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }
  async function reloadData(newOffset) {
    setLoading(true);

    const vbs = await request(`Public/PostsByCategory?ID=${params.id}&skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true`);
    setPageCount(Math.ceil(vbs.totalCount / itemsPerPage));
    setTotalCount(vbs.totalCount);
    setItems([...items, ...vbs.data]);
    setEmagazines(sliceIntoChunks([...items, ...vbs.data], 3));

    setLoading(false);
  }


  useEffect(() => {
    (async function () {
      if (page > pageCount) {
        return;
      }
      const newOffset = totalCount == 0 ? 0 : ((page - 1) * itemsPerPage) % totalCount;
      reloadData(newOffset);
    })();
  }, [page]);

  const TopBox = ({ items }) => {
    let top = items.shift();
    return (
      <>
        <LoadPanel visible={loading} />
        <div className="top-data post-box">
          <a href={`/baiviet/${top.ID}`} className="post-image">
            <img src={pathImg(top.AnhDaiDienUrl)} alt={top.AnhDaiDienUrl} />
          </a>
          <div className="post-content">
            <h2 className="post-title">
              <a href={`/baiviet/${top.ID}`}>{top.TieuDe}</a>
            </h2>
            <div className="post-date">
              <Moment format="DD/MM/YYYY">
                {top.NgayXuatBan}
              </Moment>
            </div>
          </div>
        </div>
        <div className="row">
          {items && items.map((item, index) =>
            <div className="col-sm-6 col-12">
              <div className="post-box">
                <a href={`/baiviet/${item.ID}`} className="post-image">
                  <img src={pathImg(item.AnhDaiDienUrl)} alt={item.AnhDaiDienUrl} />
                </a>
                <h2 className="post-title">
                  <a href={`/baiviet/${item.ID}`}>{item.TieuDe}</a>
                </h2>
                <div className="post-date mb-2">
                  <Moment format="DD/MM/YYYY">
                    {item.NgayXuatBan}
                  </Moment>
                </div>
                <p className="post-desc" dangerouslySetInnerHTML={{ __html: truncatedHtml(item.MoTa) }}></p>
              </div>
            </div>
          )}
        </div>
      </>
    )
  }
  const RowBox = ({ items }) => {
    return (
      <>
        <div className="row">
          {items && items.map((item, index) =>
            <div className="col-sm-4 col-12">
              <div className="post-box">
                <a href={`/baiviet/${item.ID}`} className="post-image">
                  <img src={pathImg(item.AnhDaiDienUrl)} alt={item.AnhDaiDienUrl} />
                </a>
                <h2 className="post-title">
                  <a href={`/baiviet/${item.ID}`}>{item.TieuDe}</a>
                </h2>
                <div className="post-date mb-2">
                  <Moment format="DD/MM/YYYY">
                    {item.NgayXuatBan}
                  </Moment>
                </div>
                <p className="post-desc" dangerouslySetInnerHTML={{ __html: truncatedHtml(item.MoTa) }}></p>
              </div>
            </div>
          )}
        </div>
        <div className="divider-gray" />
      </>
    )
  }
  return (
    <>
      <link rel="stylesheet" href="/assets/css/magazine.css"></link>
      <div className="header-emagazine">
        <div className="back" onClick={() => navigate(-1)}>
          <span className="isax-arrow-left1" />
        </div>
        <div className="logo">
          <span className="image">
            <a href={`/`}><img src="/assets/images/logo.svg" alt="/assets/images/logo.svg" /></a>
          </span>
          <div className="text-content">
            <span className="text-m">BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI</span>
            <div className="title">
              eMagazine
            </div>
          </div>
        </div>
        <div className="share-box">
        </div>
      </div>
      <div className="box-container-emagazine">
        {emagazines && emagazines.map((item, index) => {
          if (index == 0) {
            return (
              <TopBox items={item} />
            );
          }
          return (
            <RowBox items={item} />
          )
        }
        )}
        <div className="actions text-center">
          <button type='button' className="btn btn-primary f20 upper" onClick={() => {
            if (page + 1 > pageCount) {
              return;
            }
            setPage(page + 1);
          }}>BẤM ĐỂ XEM THÊM <i className="isax-arrow-circle-down f32" /></button>
        </div>
      </div>
      <Footer />
      <div className="scroll-top"><span className="fa fa-angle-up" /></div>
    </>
  );
}
