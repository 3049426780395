
import React, { useState, useCallback, useRef, useEffect } from 'react';

import config from '../config';
import { createCustomStore, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import LayoutHome from '../layouts/LayoutHome';
import BoxRelate from '../components/Post/BoxRelate';
import BoxSideBar from '../components/Common/BoxSideBar';
import Moment from 'react-moment';
import parse from 'html-react-parser';
import { Footer, Header } from '../components';
import HeaderEn from '../components/Layout/HeaderEn';
import FooterEn from '../components/Layout/FooterEn';
import BoxRelateEn from '../components/Post/BoxRelateEn';
import BoxSideBarEn from '../components/Common/BoxSideBarEn';
import Breadcrumb from '../components/Common/BreadCrumb';
import Notify from 'devextreme/ui/notify';
import { Helmet } from 'react-helmet';
import TinEmagazineLayout from './TinEmagazineLayout';
import TinMacDinhLayoutEn from './TinMacDinhLayoutEn';


export default function BaiVietEn() {
    const params = useParams();
    const [post, setPost] = useState();
    const navigate = useNavigate();
    const sendForm = useRef();

    useEffect(() => {
        (async function () {
            const data = await request(`Public/PostDetail?ID=${params.id}&lang=en`);
            if (data.data == null) {
                navigate("/");
                return;
            }
        
            setPost(data);
        })();
    }, [])
    useEffect(() => {
        (async function () {
            window.reloadZalo();
        })();
    }, [post])
    const changeFont = (val) => {
        let curVal = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('font-size').replace("px"));
        document.body.style.fontSize = `${curVal + val}px`;
    }
    const handlePrint = () => {
        //console.log('print');  
        let printContents = document.getElementById('articleForPrint').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        window.location.reload();
    }
    const handleSend = async (event) => {
        event.preventDefault();

        let form = sendForm.current;
        const response = await fetch(`${window.env.REACT_APP_API_URL}/Public/SendMail`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `fromUser=${form.fromUser.value}&toUser=${form.toUser.value}&fromEmail=${form.fromEmail.value}&toEmail=${form.toEmail.value}&title=${form.title.value}&address=${form.address.value}&content=${form.content.value}`
        });

        Notify({
            message: `Thành công gửi email`,
            type: 'success',
            displayTime: 3500,
        });
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`MINISTRY OF LABOUR - INVALIDS AND SOCIAL AFFAIRS-${post && post.data.TieuDe}`}</title>
            </Helmet>
            {post && post.data.ChuyenMuc && post.data.ChuyenMuc.Type == 3 &&
                <TinEmagazineLayout post={post} />
            }

            {post && post.data.ChuyenMuc && post.data.ChuyenMuc.Type != 3 &&
                <TinMacDinhLayoutEn post={post} />
            }
        </>
    );
}
