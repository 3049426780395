
import React, { useState, useCallback, useRef, useEffect } from 'react';

import { Footer, Header } from '../components';
import LayoutHome from '../layouts/LayoutHome';
import BoxRelate from '../components/Post/BoxRelate';
import BoxSideBarNav from '../components/Common/BoxSideBarNav';
import Breadcrumb from '../components/Common/BreadCrumb';
import Moment from 'react-moment';
import Notify from 'devextreme/ui/notify';
import HTMLReactParser from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

export default function TinEmagazineLayout({ post}) {
    const navigate = useNavigate();
    var element = HTMLReactParser(post.data.NoiDung, {
        replace: function (domNode) {
            if (domNode.type === 'script') {
                var script = document.createElement('script');
                if (domNode.children.length > 0) {
                    script.innerHTML = domNode.children[0].data;
                    script.onload = function () {
                    };
                }
                document.head.appendChild(script);
            }
        }
    });
  return (
    <>
        <link rel="stylesheet" href="/assets/css/magazine.css"></link>
        <div className="header-emagazine">
            <div className="back" onClick={() => navigate(-1)}>
                <span className="isax-arrow-left1" />
            </div>
            <div className="logo">
                <span className="image">
                    <a href={`/`}><img src="/assets/images/logo.svg" alt="/assets/images/logo.svg" /></a>
                </span>
                <div className="text-content">
                    <span className="text-m">BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI</span>
                    <div className="title">
                        eMagazine
                    </div>
                </div>
            </div>
            <div className="share-box">
                <div className="zalo-share-button btn btn-primary btn-xs py-1" style={{ margin: '0px' }} data-href={`http://molisa.gov.vn/baiviet/${post.data.ID}`} data-oaid="2381586077622143744" data-layout="3" data-color="white" data-share-type="1" data-customize="true" >
                    <img src="/assets/images/social/zalo.png" height={"40"} alt="zalo.png" />
                </div>
                <a className='btn btn-primary btn-xs py-1' href={`https://www.facebook.com/sharer/sharer.php?u=http://molisa.gov.vn/baiviet/${post.data.ID}&quote=${post.data.TieuDe}`}>
                    <img src="/assets/images/social/fb.png" height={"40"} alt="fb.png" />
                </a>
            </div>
        </div>
        {parse(post.data.NoiDung)}
        <Footer />
    </>
  );
}
