import React, { useState, useCallback, useRef } from 'react';

import BoxSideBarNav from './BoxSideBarNav';
import BoxHighLightEn from './BoxHighLightEn';
import BoxSideBarNavEn from './BoxSideBarNavEn';

export default function BoxSideBarEn({ idChuyenMuc, showSame= false, showChild=false }) {
  return (
    <>
      <div className="box-sidebar">
          <BoxHighLightEn />
      </div>
    </>
  )
}
