import React from 'react';
import BoxNew from '../Common/BoxNew';
export default function BoxNews({ title, children }) {
  return (
    <>
      <div className="main-title">
        <div className="title">
          <span className="icon isax-document-text"></span>
          <h2 className="text-m">Tin tức</h2>
        </div>
        <ul className="list-cat">
          <li className="active"><a href="/category/26">Địa phương - cơ sở</a></li>
          <li><a href="/category/286">Đảng - Đoàn thể</a></li>
          <li><a href="/category/254">Các vấn đề khác</a></li>
        </ul>
      </div>
      <div className="row">
        {[23, 25, 24, 26].map((item) =>
          <BoxNew idChuyenmuc={item} className="col-md-3 col-12" />
        )}
      </div>
    </>
  )
}
