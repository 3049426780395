import React, { useState, useCallback, useRef, useEffect } from 'react';

import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import { useAuth } from '../../contexts/auth';
import { useTranslation } from 'react-i18next';
import Tree from '../Common/Tree';
import { listToTree, request } from '../../utils/api';
import { useNavigate } from "react-router-dom";

export default function HeaderEn({ subtitle = "SOCIALIST REPUBLIC OF VIETNAM", title = "MINISTRY OF LABOUR - INVALIDS AND SOCIAL AFFAIRS" }) {
  const [show, setShow] = useState(false);
  const [root, setRoot] = useState();
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation();
  const handleToggle = () => {
    setShow(!show);
  };
  useEffect(() => {
    (async function () {
      const menus = await request(`Public/Menu?lang=en`);
      var temp = listToTree(menus.data, 'ID', 'IDMenuCha');
      temp = temp[0].children;
      setRoot(temp);
    })();
  }, [])
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      let keyword = event.target.value;
      window.open(`/search/${keyword}`, '_self')
    }
  }
  return (
    <>
      <div className={show ? 'overlay-common show' : 'overlay-common'} onClick={handleToggle}></div>
      <div className="top-header">
        <div className="container">
          <div className="content-box">
            <a className="logo" href="/">
              <span className="image">
                <img src="/assets/images/logo.svg" alt={title} />
              </span>
              <div className="text-content">
                <div className="subtitle">{subtitle}</div>
                <div className="title">{title}</div>
              </div>
            </a>
            <div className="top-right">
              <div className="links">
                <a href="http://intranet.molisa.gov.vn/Login.aspx">
                  <span className="isax-book1"></span>
                  eMolisa
                </a>
                <a href="https://mail.molisa.gov.vn/">
                  <span className="isax-sms1"></span>
                  Email
                </a>
                <a href={`/sitemap`}>
                  <span className="isax-hierarchy1"></span>
                  Sitemap
                </a>
              </div>
              <div className="lang">
                <a href='https://molisa.gov.vn/' className="item">
                  <img src="/assets/images/flags/vi.png" alt="VI" />
                </a>
                
                <a href='https://english.molisa.gov.vn/' className="item">
                  <img src="/assets/images/flags/en.png" alt="EN" />
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="header">
        <div className="container">
          <div className="menus-box">
            <ul className={show ? 'menus show' : 'menus'}>
              <li>
                <a href="/" className="nav-link" name="homepage" id="homepage">
                  <span className="-ap icon-home">
                    <span className="d-none">Home</span>
                  </span>
                </a>
              </li>
              {root &&
                <Tree
                  key={root.id}
                  data={root}
                  itemTemplate={(item, hasChildren, branch) => {
                    return (
                      <li>
                        <a key={item.id} href={item.URL} className={`nav-link  ${hasChildren ? 'dropdown-toggle' : ''}`}>
                          {item.TenMenu}
                        </a>
                        {hasChildren && branch}
                      </li>
                    )
                  }}
                  renderChilds={(item, hasChildren, renderNode, renderChilds) => {
                    if (hasChildren) {
                      return <ul className="dropdown-menu">
                        <Tree
                          key={item.id}
                          data={item.children}
                          renderNode={renderNode}
                          renderChilds={renderChilds}
                          itemTemplate={(item, hasChildren, branch) => {
                            return (
                              <li>
                                <a key={item.id} href={item.URL} className={`nav-link  ${hasChildren ? 'dropdown-toggle' : ''}`}>
                                  {item.TenMenu}
                                </a>
                                {hasChildren && branch}
                              </li>
                            )
                          }}
                        />
                      </ul>;
                    }
                    return null;
                  }}
                />
              }
            </ul>
            <div className="nav-toggle" onClick={handleToggle}>
              <span className="-ap isax-menu1"></span>
            </div>
            <div className="input-search">
              <span className="icon isax-search-normal-11"></span>
              <input type="text" name='topbarkeyword' className="form-control" placeholder="Search..." title="Search..." aria-label="Search..." id="topbarkeyword" onKeyPress={handleKeyPress} />
            </div>
          </div>
        </div>

      </div>
    </>
  )
}
