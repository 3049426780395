import React, { useState, useCallback, useRef, useEffect } from 'react';
import { request } from '../../utils/api';

export default function BoxSideBarNav({idChuyenMuc = null, showSame= false, showChild=false}) {
  const [chuyenmuc, setChuyenmuc] = useState();
  useEffect(() => {
      (async function () {
          const vbs = await request(`Public/ChuyenMucDetail?ID=${idChuyenMuc}`);
          setChuyenmuc(vbs);
      })();
  }, [])
  return (
    <>
      {chuyenmuc && 
        <div className="box-sidebar">
            <div className="sidebar-cat">
                {chuyenmuc && chuyenmuc.parents && chuyenmuc.parents.length >= 2 && 
                  <div className="title">
                    {chuyenmuc.parents.at(-2)?.TenChuyenMuc}
                  </div>
                }
                <div className="menus">
                  {chuyenmuc && showSame && chuyenmuc.samelevels.map((item) => 
                    <a href={`/chuyenmuc/${item.ID}`} className={chuyenmuc.data.ID == item.ID?'active':''}>{item.TenChuyenMuc}</a>
                  )}
                  {chuyenmuc && showChild && chuyenmuc.childs.map((item) => 
                    <a href={`/chuyenmuc/${item.ID}`} className={chuyenmuc.data.ID == item.ID?'active':''}>{item.TenChuyenMuc}</a>
                  )}
                </div>
            </div>
        </div>
      }
    </>
  )
}
