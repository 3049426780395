
import React, { useState, useCallback, useRef } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import config from '../config';
import { createCustomStore } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Header } from '../components';
import { Helmet } from 'react-helmet';

export default function VanBanQPPL() {
  const params = useParams();
  const pStyle = {
    display: "block",
    height: "100vh",
    width: "100vw",
    position: "absolute",
    right: "0px"
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI-Văn bản quy phạm pháp luật`}</title>
      </Helmet>
      <Header
        menuToggleEnabled
      />
      <div className={"main-wrapper"}>
        <div className="container">
          <div class="breadcrumb">
            <a href="/" className="breadcrumb-item">Trang chủ</a>
            <a href="/vanbanqppl" class="breadcrumb-item">Văn bản quy phạm pháp luật</a>
          </div>
          <embed src={`https://vbpl.vn/bolaodong/Pages/iHome.aspx?Keyword=${params.keyword ? params.keyword : ""}`} style={pStyle} height="100%" width="100%" frameBorder="0">
          </embed>
        </div>
      </div>
    </>
  );
}
