import React, { useEffect, useState } from 'react';
import { pathImg, proxyPathImg, request } from '../../utils/api';
import config from '../../config';
export default function BoxMedia({ title = "ĐA PHƯƠNG TIỆN", children }) {
    const [data, setData] = useState();
    useEffect(() => {
        (async function () {
            const data = await request(`Public/BoxMedia`);
            setData(data);
        })();
    }, [])
    return (
        <>
            {data &&
                <>
                    <div className="main-title">
                        <div className="title">
                            <span className="icon isax-video-vertical"></span>
                            <h2 className="text-m">{title}</h2>
                        </div>
                        <ul className="list-cat">
                            {/* <li className="active"><a href="http://media.molisa.gov.vn/?page=video">Video</a></li>
                            <li><a href="http://media.molisa.gov.vn/?page=audio">Audio </a></li>
                            <li><a href="http://media.molisa.gov.vn/?page=album">Album</a></li>
                            <li><a href="/chuyenmuc/10514">Infographic</a></li> */}

                            <li><a href="/medianoibat?type=video">Video</a></li>
                            <li><a href="/medianoibat?type=audio">Audio </a></li>
                            <li><a href="/medianoibat?type=anh">Album</a></li>
                            <li><a href="/chuyenmuc/10514">Infographic</a></li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-12">
                            {data.medias && <>
                                <h3 className="main-subtitle">
                                    <a className="text-m" href={`${data.medias.LienKet}`}>Video</a>
                                </h3>
                                <a className="video-box mb-3" href={`${data.medias.LienKet}`}>
                                    <span className="image">
                                        <img src={proxyPathImg(data.medias.LinkAnhDaiDien)} alt={data.medias.TieuDe} />
                                    </span>
                                    <div className="title-box">
                                        <span className="icon_fluent_play_circle_24_regular -ap icon"></span>
                                        <span className="title">{data.medias.TieuDe}</span>

                                    </div>
                                </a>
                            </>
                            }
                        </div>
                        <div className="col-md-6 col-12">
                            {data.infographics && <>
                                <h3 className="main-subtitle">
                                    <a className="text-m" href={`/baiviet/${data.infographics.ID}`}>Infographic</a>
                                </h3>
                                <a className="infographic-box" href={`/baiviet/${data.infographics.ID}`}>
                                    {data.infographics && <>
                                        <span className="image">
                                            <img src={pathImg(data.infographics.AnhDaiDienUrl)} alt={pathImg(data.infographics.AnhDaiDienUrl)} />
                                        </span>
                                    </>
                                    }
                                </a>
                            </>
                            }
                        </div>
                    </div>
                </>
            }
        </>
    )
}
