
import React, { useState, useCallback, useRef, useEffect } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import config from '../config';
import { createCustomStore, interleave, pathFile, pathImg, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import Moment from 'react-moment';
import BoxSideBarVBCDDH from '../components/Common/BoxSideBarVBCDDH';
import { useAuth } from '../contexts/auth';
import { LoadPanel } from 'devextreme-react';
import Pagination from "react-js-pagination";
import { Helmet } from 'react-helmet';

export default function VanBanChiDaoDieuHanh() {
  const [loading, setLoading] = useState(true);
  const [rendered, setRendered] = useState(false);

  const [coquans, setCoquans] = useState();
  const [linhvucs, setLinhvucs] = useState();
  const [hinhthucs, setHinhthucs] = useState();
  const [banners, setBanners] = useState();
  const [vanban, setVanban] = useState();

  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [items, setItems] = useState();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchForm = useRef();
  const id = params.id;
  const LinhVucID = searchParams.get("LinhVucID");
  const HinhThucID = searchParams.get("HinhThucID");
  const CoQuanBanHanhID = searchParams.get("CoQuanBanHanhID");
  const auth = useAuth();

  useEffect(() => {
    (async function () {
      const data = await request(`Public/CoQuanBanHanh?lang=vi`);
      setCoquans(data);
      const data2 = await request(`Public/LinhVuc`);
      setLinhvucs(data2);
      const data3 = await request(`Public/HinhThucVanBan?lang=vi`);
      setHinhthucs(data3);
      const bans = await request(`/Public/BannerByViTri?ID_ViTri=3210`);
      setBanners(bans);

      if (id) {
        const bans = await request(`/Public/VanBanDetail?ID=${id}`);
        if (bans.data != null) {
          setVanban(bans);
        }
      }
    })();
  }, [])

  useEffect(() => {
    if (linhvucs && hinhthucs && coquans) {
      reloadData(0);
    }
  }, [linhvucs, hinhthucs, coquans])


  const getFilters = () => {
    let form = searchForm.current;
    setVanban();
    let filter = [];
    filter.push(["LopVanBanID", "=", "104"]);
    if (form.keyword && form.keyword.value) {
      filter.push(["TrichYeu", "contains", form.keyword.value]);
    }
    if (form.sokyhieu.value) {
      filter.push(["SoKyHieu", "contains", form.sokyhieu.value]);
    }
    if (form.trichyeu.value) {
      filter.push(["TrichYeu", "contains", form.trichyeu.value]);
    }
    if (form.fromdateNBH.value) {
      filter.push(["NgayBanHanh", ">=", form.fromdateNBH.value]);
    }
    if (form.todateNBH.value) {
      filter.push(["NgayBanHanh", "<=", form.todateNBH.value]);
    }
    if (form.fromdateNHL.value) {
      filter.push(["NgayHieuLuc", ">=", form.fromdateNHL.value]);
    }
    if (form.todateNHL.value) {
      filter.push(["NgayHieuLuc", "<=", form.todateNHL.value]);
    }
    if (form.linhvuc.value) {
      filter.push(["LinhVucID", "=", form.linhvuc.value]);
    }
    if (form.hinhthuc.value) {
      filter.push(["ID_HinhThucVanBan", "=", form.hinhthuc.value]);

    }
    if (form.coquanbanhanh.value) {
      filter.push(["CoQuanBanHanhID", "=", form.coquanbanhanh.value]);
    }
    return filter;
  }

  const handleSearch = async (event) => {
    event.preventDefault();
    reloadData(0);
  }
  // Invoke when user click to request another page.
  const handlePageClick = async (pageNumber) => {
    setLoading(true);
    setActivePage(pageNumber);
    const newOffset = ((pageNumber - 1) * itemsPerPage) % totalCount;
    reloadData(newOffset);
  };
  async function reloadData(newOffset) {
    let filter = getFilters();
    setLoading(true);
    let filterStr = JSON.stringify(interleave(filter, "and"));
    const vbs = await request(`Public/VanBan?lang=vi&skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true&filter=${filterStr}`);
    setTotalCount(vbs.totalCount);
    setItems(vbs.data);
    setLoading(false);
  }
  return (
    <>
      {!vanban &&
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI-Văn bản chỉ đạo điều hành`}</title>
        </Helmet>
      }
      {vanban &&
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI-${vanban.data.SoKyHieu}`}</title>
        </Helmet>
      }
      <LoadPanel visible={loading} />
      <div className="breadcrumb">
        <a href="/" className="breadcrumb-item">Trang chủ</a>
        <a href="/vanbanchidao" class="breadcrumb-item">Văn bản chỉ đạo điều hành</a>
        {vanban &&
          <a href={`/vanbanchidao/${vanban.data.ID}`} className="breadcrumb-item">{vanban.data.SoKyHieu}</a>
        }
      </div>
      <div className="row">
        <div className="col-sm-3 col-12">
          <div className="box-sidebar -menus">
            <div className="body">
              <ul className="vanban-menu">
                <li className="selected">
                  <a href="/vanbanqppl">Văn bản quy phạm pháp luật </a>
                  <a href="/vanbanchidao">Văn bản chỉ đạo điều hành </a>
                  <a href="/vanbanquocte">Văn bản hợp tác quốc tế </a>
                  <ul>
                    <li className="g-sb-tt">Lĩnh vực</li>
                    {linhvucs && linhvucs.data.map((item, index) =>
                      <li className={LinhVucID == item.ID ? "active" : ""}><a href={`/vanbanchidao?LinhVucID=${item.ID}`}> <span className="vb-icon"><span className="fa fa-angle-right" /></span><span className="vb-text"> {item.TenLinhVuc} </span></a></li>
                    )
                    }
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-12">
          {!vanban &&
            <div className="box-dtvb">
              <form ref={searchForm} onSubmit={handleSearch}>
                <div className="box-form" style={{ background: '#f6f7f8', padding: '20px', border: '1px solid #e6e7e8', marginBottom: '20px' }}>
                  <div className="row" style={{ margin: '0px -5px' }}>
                    <div className="col-sm-6 col-12" style={{ padding: '0px 5px' }}>
                      <div className="form-group">
                        <input className="form-control" id="keyword" name="keyword" placeholder="Nhập nội dung văn bản cần tìm..." type="text" />
                      </div>
                    </div>
                    <div className="col-sm-2 col-12" style={{ padding: '0px 5px' }}>
                      <div className="form-group">
                        <button className="btn btn-danger" type='submit'>
                          <span className="fa fa-search">
                          </span>
                          Tìm kiếm
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="top-actions">
                    <button className="btn btn-filter" data-toggle="collapse" data-target="#formFilter" type='button'>
                      <span className="-ap icon-repo" />
                      Tìm kiếm nâng cao <span className="fa fa-caret-down" />
                    </button>
                    <div className="total">
                      Tổng số văn bản: <strong className="red">{totalCount}</strong>
                    </div>
                  </div>

                  <div className="filter-form collapse mt-2" style={{ padding: '0px' }} id="formFilter">
                    <div className="note red">(gõ nội dung vào một trong các ô tương ứng và nhấn nút Tìm kiếm)</div>
                    <div className="form-group">
                      <label htmlFor="sokyhieu" className="label-text">Số hiệu</label>
                      <div className="input">
                        <input type="text" className="form-control" placeholder="Số hiệu" id="sokyhieu" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="label-text" htmlFor="trichyeu">Trích yếu nội dung</label>
                      <div className="input">
                        <input type="text" className="form-control" placeholder="Trích yếu nội dung" id="trichyeu" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="label-text">Ngày ban hành</label>
                    </div>
                    <div className="form-group -date">
                      <label className="label-text" htmlFor="fromdateNBH">Từ</label>
                      <div className="input">
                        <input type="date" className="form-control" placeholder="dd/mm/yyyy" id="fromdateNBH" />
                      </div>
                      <label htmlFor="todateNBH" className="label-text -date">Đến</label>
                      <div className="input">
                        <input type="date" className="form-control" placeholder="dd/mm/yyyy" id="todateNBH" />
                      </div>
                    </div>
                    <div className="form-group ">
                      <label className="label-text">Ngày hiệu lực</label>
                    </div>
                    <div className="form-group -date">
                      <label className="label-text" htmlFor="fromdateNHL">Từ</label>
                      <div className="input">
                        <input type="date" className="form-control" placeholder="dd/mm/yyyy" id="fromdateNHL" />
                      </div>
                      <label className="label-text -date" htmlFor="todateNHL">Đến</label>
                      <div className="input">
                        <input type="date" className="form-control" placeholder="dd/mm/yyyy" id="todateNHL" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="label-text" htmlFor="linhvuc">Lĩnh vực</label>
                      <div className="input">
                        <div className="select-custom">
                          <select name id="linhvuc" className="form-control">
                            <option selected="selected" value="">-Tất cả-</option>
                            {linhvucs && linhvucs.data.map((item, index) =>
                              <option value={item.ID} selected={LinhVucID == item.ID}>{item.TenLinhVuc}</option>
                            )
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="label-text" htmlFor="hinhthuc">Hình thức văn bản</label>
                      <div className="input">
                        <div className="select-custom">
                          <select name id="hinhthuc" className="form-control">
                            <option selected="selected" value="">-Tất cả-</option>
                            {hinhthucs && hinhthucs.data.map((item, index) =>
                              <option value={item.ID} selected={HinhThucID == item.ID}>{item.TenHinhThucVanBan}</option>
                            )
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="label-text" htmlFor="coquanbanhanh">Cơ quan ban hành</label>
                      <div className="input">
                        <div className="select-custom">
                          <select name id="coquanbanhanh" className="form-control">
                            <option selected="selected" value="">-Tất cả-</option>
                            {coquans && coquans.data.map((item, index) =>
                              <option value={item.ID} selected={CoQuanBanHanhID == item.ID}>{item.TenCoQuanBanHanh} </option>
                            )
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="actions form-group">
                      <label className="label-text" />
                      <div className="input">
                        <button type='submit' className="btn-submit btn-success btn">Tìm kiếm</button>
                        <button type='button' className="btn-cancel btn btn-default" data-toggle="collapse" data-target="#formFilter">Hủy bỏ</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="main_vbtable  table-vanban">
                <table className="table">
                  <colgroup>
                    <col class="w-15" />
                    <col class="w-20" />
                    <col class="w-15" />
                    <col class="w-15" />
                    <col class="w-35" />
                  </colgroup>
                  <thead id="headvanban">
                    <tr>
                      <th>Số hiệu</th>
                      <th>Cơ quan ban hành</th>
                      <th>Ngày ban hành</th>
                      <th>Lĩnh vực</th>
                      <th>Tên văn bản</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items && items.map((item) =>
                      <tr>
                        <td><a href={`/vanbanchidao/${item.ID}`}>{item.SoKyHieu}</a></td>
                        <td>
                          {item.TenCoQuanBanHanh}
                        </td>
                        <td>
                          {item.NgayBanHanh && <>
                            <Moment format="DD/MM/YYYY">
                              {item.NgayBanHanh}
                            </Moment>
                          </>
                          }
                        </td>
                        <td>{item.TenLinhVuc}</td>
                        <td>
                          <a href={`/vanbanchidao/${item.ID}`}> {item.TrichYeu}</a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  activePage={activePage}
                  itemClass='page-item'
                  linkClass='page-link'
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={5}
                  onChange={handlePageClick}
                />
              </div>
            </div>
          }
          {vanban &&
            <div className="box-dtvb">
              <form ref={searchForm} onSubmit={handleSearch}>
                <div className="top-actions">
                  <div className="group-buttons">
                    <button type='button' className="btn btn-filter btn-primary" data-toggle="collapse" data-target="#formFilter">Lọc</button>
                    <button type='submit' className="btn btn-filter btn-success">Tìm kiếm</button>
                  </div>
                  <div className="total">
                    {/* Tổng số văn bản: <strong className="red">{totalCount}</strong> */}
                  </div>
                </div>

                <div className="filter-form collapse" id="formFilter">
                  <div className="note red">(gõ nội dung vào một trong các ô tương ứng và nhấn nút Tìm kiếm)</div>
                  <div className="form-group">
                    <label htmlFor="idsokyhieu" className="label-text">Số hiệu</label>
                    <div className="input">
                      <input type="text" className="form-control" placeholder="Số hiệu" id="sokyhieu" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="label-text" htmlFor="trichyeu">Trích yếu nội dung</label>
                    <div className="input">
                      <input type="text" className="form-control" placeholder="Trích yếu nội dung" id="trichyeu" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="label-text">Ngày ban hành</label>
                  </div>
                  <div className="form-group -date">
                    <label className="label-text" htmlFor="fromdateNBH">Từ</label>
                    <div className="input">
                      <input type="date" className="form-control" placeholder="dd/mm/yyyy" id="fromdateNBH" />
                    </div>
                    <label htmlFor="todateNBH" className="label-text -date">Đến</label>
                    <div className="input">
                      <input type="date" className="form-control" placeholder="dd/mm/yyyy" id="todateNBH" />
                    </div>
                  </div>
                  <div className="form-group ">
                    <label className="label-text">Ngày hiệu lực</label>
                  </div>
                  <div className="form-group -date">
                    <label className="label-text" htmlFor="fromdateNHL">Từ</label>
                    <div className="input">
                      <input type="date" className="form-control" placeholder="dd/mm/yyyy" id="fromdateNHL" />
                    </div>
                    <label className="label-text -date" htmlFor="todateNHL">Đến</label>
                    <div className="input">
                      <input type="date" className="form-control" placeholder="dd/mm/yyyy" id="todateNHL" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="label-text" htmlFor="linhvuc">Lĩnh vực</label>
                    <div className="input">
                      <div className="select-custom">
                        <select name id="linhvuc" className="form-control">
                          <option selected="selected" value="">-Tất cả-</option>
                          {linhvucs && linhvucs.data.map((item, index) =>
                            <option value={item.ID} selected={LinhVucID == item.ID}>{item.TenLinhVuc}</option>
                          )
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="label-text" htmlFor="hinhthuc">Hình thức văn bản</label>
                    <div className="input">
                      <div className="select-custom">
                        <select name id="hinhthuc" className="form-control">
                          <option selected="selected" value="">-Tất cả-</option>
                          {hinhthucs && hinhthucs.data.map((item, index) =>
                            <option value={item.ID} selected={HinhThucID == item.ID}>{item.TenHinhThucVanBan}</option>
                          )
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="label-text" htmlFor="coquanbanhanh">Cơ quan ban hành</label>
                    <div className="input">
                      <div className="select-custom">
                        <select name id="coquanbanhanh" className="form-control">
                          <option selected="selected" value="">-Tất cả-</option>
                          {coquans && coquans.data.map((item, index) =>
                            <option value={item.ID} selected={CoQuanBanHanhID == item.ID}>{item.TenCoQuanBanHanh} </option>
                          )
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="actions form-group">
                    <label className="label-text" />
                    <div className="input">
                      <button type='submit' className="btn-submit btn-success btn">Tìm kiếm</button>
                      <button type='button' className="btn-cancel btn btn-default" data-toggle="collapse" data-target="#formFilter">Hủy bỏ</button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="main_vbtable  table-vanban">
                <table className="table">
                  <tbody>
                    <tr>
                      <td style={{ width: '150px' }}><strong>Số hiệu</strong></td>
                      <td>{vanban.data.SoKyHieu}</td>
                    </tr>
                    <tr>
                      <td><strong>Trích yếu nội dung</strong></td>
                      <td>{vanban.data.TrichYeu}</td>
                    </tr>
                    <tr>
                      <td><strong>Ngày ban hành</strong></td>
                      <td>
                        {vanban.data.NgayBanHanh && <Moment format="DD/MM/YYYY">
                          {vanban.data.NgayBanHanh}
                        </Moment>}
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Ngày có hiệu lực</strong></td>
                      <td>
                        {vanban.data.NgayCoHieuLuc && <Moment format="DD/MM/YYYY">
                          {vanban.data.NgayCoHieuLuc}
                        </Moment>}
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Ngày hết hiệu lực</strong></td>
                      <td>
                        {vanban.data.NgayHetHan && <Moment format="DD/MM/YYYY">
                          {vanban.data.NgayHetHan}
                        </Moment>}
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Hình thức văn bản</strong></td>
                      <td>{vanban.data.HinhThucVanBan && vanban.data.HinhThucVanBan.TenHinhThucVanBan}</td>
                    </tr>
                    <tr>
                      <td><strong>Lĩnh vực</strong></td>
                      <td>
                        {vanban.data.VanBan_LinhVuc && vanban.data.VanBan_LinhVuc.map((item, index) =>
                          <>
                            {item.LinhVuc.TenLinhVuc} <br />
                          </>
                        )
                        }
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Cơ quan ban hành</strong></td>
                      <td>
                        {vanban.data.VanBan_CoQuanBanHanh && vanban.data.VanBan_CoQuanBanHanh.map((item, index) =>
                          <>
                            {item.CoQuanBanHanh.TenCoQuanBanHanh} <br />
                          </>
                        )
                        }
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Người ký duyệt</strong></td>
                      <td>
                        {vanban.data.NguoiKy}
                      </td>
                    </tr>
                    <tr>
                    </tr>
                    <tr>
                      <td><strong>Văn bản liên quan</strong></td>
                      <td>
                        {vanban.data.VanBan_LienQuans && vanban.data.VanBan_LienQuans.map((item, index) =>
                          <>
                            <a href={`/vanbanchidao/${item.VanBan.ID}`}>{item.VanBan.TrichYeu}</a> <br />
                          </>
                        )
                        }
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Download</strong></td>
                      <td>
                        {vanban.data.VanBan_Files && vanban.data.VanBan_Files.map((item, index) =>
                          <>
                            <a href={pathFile(item.File.LinkLuuTru)}>{item.File.Ten}</a> <br />
                          </>
                        )
                        }
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Chi tiết văn bản</strong></td>
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>
        <div className="col-sm-3 col-12">
          <div className="box-sidebar -menus">
            <BoxSideBarVBCDDH id={104} />
          </div>
          {/* <ul className="list-link">
            {banners && banners.data.map((item, index) =>
              <li>
                <a href={item.URL}>
                  <img src={pathImg(item.AnhBannerUrl)} alt={item.AnhBannerUrl} />
                </a>
              </li>
            )
            }
          </ul> */}
        </div>
      </div>
    </>

  );
}
