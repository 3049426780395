
import React, { useState, useCallback, useRef, useEffect } from 'react';

import Moment from 'react-moment';
import config from '../config';
import { createCustomStore, pathImg, request, truncatedHtml } from '../utils/api';
import { useParams } from 'react-router-dom';
import BoxTinTongHop from '../components/Home/BoxTinTongHop';
import Pagination from "react-js-pagination";
import BoxSideBarEn from '../components/Common/BoxSideBarEn';


export default function ChuyenMucTongHopEnLayout({ chuyenmuc }) {
    const params = useParams();

    const [itemsPerPage, setItemsPerPage] = useState(8);
    const [activePage, setActivePage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [items, setItems] = useState();
    const [hots, setHots] = useState();

    useEffect(() => {
        (async function () {
            let queryArr = chuyenmuc.topposts.map(x => x.ID).join("&exId=");
            const vbs = await request(`Public/PostsByCategory?ID=${chuyenmuc.data.ID}&exId=${queryArr}&skip=0&take=${itemsPerPage}&requireTotalCount=true`);
            setTotalCount(vbs.totalCount);
            setItems(vbs.data);
        })();
    }, [])


    // Invoke when user click to request another page.
    const handlePageClick = async (pageNumber) => {
        let queryArr = chuyenmuc.topposts.map(x => x.ID).join("&exId=");

        const newOffset = ((pageNumber - 1) * itemsPerPage) % totalCount;
        const vbs = await request(`Public/PostsByCategory?ID=${chuyenmuc.data.ID}&exId=${queryArr}&skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true`);
        setActivePage(pageNumber);
        setTotalCount(vbs.totalCount);
        setItems(vbs.data);
    };
    const handleChangePage = (event) => {
        setItemsPerPage(event.target.value);
    };

    return (
        <div className="htvb-box">
            <BoxSideBarEn idChuyenMuc={chuyenmuc.data.ID} showSame={true} />
            <div className="box-content">
                <div className="top-news-box">
                    <div className="top-news">
                        {chuyenmuc.topposts.length > 0 &&
                            <>
                                <div className="hotnews">
                                    <a className="post-image">
                                        <img src={pathImg(chuyenmuc.topposts[0].AnhDaiDienUrl)} alt={chuyenmuc.topposts[0].AnhDaiDienUrl} />
                                    </a>
                                    <h2 className="post-title">
                                        <a href={`/topic/${chuyenmuc.topposts[0].ID}`}>{chuyenmuc.topposts[0].TieuDe}</a>
                                    </h2>
                                    <p className="post-desc" dangerouslySetInnerHTML={{ __html: truncatedHtml(chuyenmuc.topposts[0].MoTa) }}></p>
                                </div>
                            </>
                        }
                        <div className="list-news-hot">
                            {chuyenmuc.topposts.map((item, index) => {
                                if (index == 0) {
                                    return;
                                }
                                return (
                                    <div className="item">
                                        <a href={`/topic/${item.ID}`} className="image">
                                            <img src={pathImg(item.AnhDaiDienUrl)} alt={item.AnhDaiDienUrl} />
                                        </a>
                                        <a className="text-content" href={`/topic/${item.ID}`}>
                                            {truncatedHtml(item.TieuDe, 20)}
                                        </a>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
                <div className="divider-gray"></div>
                <div className="list-tt-c3">
                    {items && items.map((item) =>
                        <div className="item">
                            <div className="date-box">
                                <Moment format="DD/MM/YYYY">
                                    {item.NgayXuatBan}
                                </Moment>
                            </div>

                            <div className="text-content">
                                <h2 className="post-title">
                                    <a href={`/topic/${item.ID}`}>{item.TieuDe}</a>
                                </h2>
                                <p className="post-desc" dangerouslySetInnerHTML={{ __html: truncatedHtml(item.MoTa) }}>
                                </p>
                            </div>
                            <a className="post-image">
                                <img src={pathImg(item.AnhDaiDienUrl)} alt={item.AnhDaiDienUrl} />
                            </a>
                        </div>
                    )}
                </div>
                <div className="pagination-bottom">
                    <div className="page-text">
                        <span className="text-m">Number of rows</span>
                        <div className="select-custom">
                            <select title="Number of rows" name="" id="" className="form-control" onChange={handleChangePage}>
                                <option value="">8</option>
                                <option value="">10</option>
                                <option value="">20</option>
                            </select>
                        </div>
                    </div>

                    <Pagination
                        activePage={activePage}
                        itemClass='page-item'
                        linkClass='page-link'
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={5}
                        onChange={handlePageClick}
                    />
                </div>
            </div>
        </div>
    );
}
