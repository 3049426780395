import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import config from '../../config';
import { pathImg, request } from '../../utils/api';

export default function BoxBanner({ idViTri, children }) {
  const [banners, setBanners] = useState();
  useEffect(() => {
      (async function () {
          const data = await request(`/Public/BannerByViTri?ID_ViTri=${idViTri}`);
          setBanners(data);
      })();
  }, [])

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows:false,
};
  return (
    <>
      <Slider className="list-banner" {...settings}>
        {banners && banners.data.map((item, index) => 
            <div className="item">
              <a href={item.URL} className="banner my-4">
                <img src={pathImg(item.AnhBannerUrl)} alt={item.AnhBannerUrl} />
              </a>
            </div>
          )
        }
      </Slider>
    </>
  )
}
