import React, { useState, useCallback, useRef, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import BoxNewEn from '../components/Common/BoxNewEn';
import BoxTinNoiBatEn from '../components/Home/BoxTinNoiBatEn';

export default function TinTucEn() {
    const params = useParams();
    return (
        <>
            <div className="breadcrumb">
                <a href="/" className="breadcrumb-item">Home</a>
                <a href="/news" className="breadcrumb-item">News</a>
            </div>
            <h1 className="title-main main bold f18 mb-4 text-uppercase">News</h1>
            <div className="htvb-box">
                <div className="box-sidebar">
                    <div className="box-sidebar">
                        <div className="sidebar-cat">
                            <div className="title">NEWS</div>
                            <div className="menus">
                                <a href={`/category/10494`} className={'active'}>External Affairs</a>
                                <a href={`/category/10495`}>Labour</a>
                                <a href={`/category/10496`}>Social affairs</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box-content">
                    <BoxTinNoiBatEn id={10494} />
                </div>
            </div>
            <div className="row">
                <BoxNewEn idChuyenmuc={10494} className="col-md-4 col-12" />
                <BoxNewEn idChuyenmuc={10495} className="col-md-4 col-12" />
                <BoxNewEn idChuyenmuc={10496} className="col-md-4 col-12" />
            </div>
        </>
    );
}
