
import React, { useState, useCallback, useRef, useEffect } from 'react';

import Moment from 'react-moment';
import config from '../config';
import { createCustomStore, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxRelate from '../components/Post/BoxRelate';
import { useParams } from 'react-router-dom';
import LayoutHome from '../layouts/LayoutHome';
import BoxSideBar from '../components/Common/BoxSideBar';
import ReactPaginate from 'react-paginate';
import BoxSideBarEn from '../components/Common/BoxSideBarEn';
import { Helmet } from 'react-helmet';


export default function SiteMapEn({ chuyenmuc }) {
    const params = useParams();
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`MINISTRY OF LABOUR - INVALIDS AND SOCIAL AFFAIRS-Sitemap`}</title>
            </Helmet>
            <div className="breadcrumb">
                <a href="/" className="breadcrumb-item">Home</a>
                <a href="/sitemap" className="breadcrumb-item">Sitemap</a>
            </div>
            <div className="row">
                <div className="main-left col-sm-8 col-12">
                    <div className="box-sitemap">
                        <h1 className="title">SITEMAP</h1>
                        <ul className="sitemap">
                            <li className="item">
                                <a href="/"> HOME</a>
                            </li>
                            <li className="item">
                                <a href="/dutiesresponsibilities">INTRODUCTION</a>
                                <ul>
                                    <li><a href="/organizational">Organizational structure</a></li>
                                    <li><a href="/leadership">Ministrial leaders</a></li>
                                </ul>
                            </li>
                            <li className="item">
                                <a href="/news"> NEWS</a>
                            </li>
                            <li className="item">
                                <a href="/official">DOCUMENTS</a>
                            </li>
                            <li className="item">
                                <a href="http://media.molisa.gov.vn/"> MULTIMEDIA</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-4 col-12">
                    <BoxSideBarEn idChuyenMuc={23} showSame={true} />
                </div>
            </div>
        </>
    );
}
