
import React, { useState, useCallback, useRef, useEffect } from 'react';

import Moment from 'react-moment';
import config from '../config';
import { createCustomStore, pathImg, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxRelate from '../components/Post/BoxRelate';
import { useParams } from 'react-router-dom';
import LayoutHome from '../layouts/LayoutHome';
import BoxSideBar from '../components/Common/BoxSideBar';
import Pagination from "react-js-pagination";


export default function TrangThanhPhan({ }) {
    const params = useParams();

    const [itemsPerPage, setItemsPerPage] = useState(18);
    const [activePage, setActivePage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [nhansu, setNhansu] = useState();
    const [items, setItems] = useState();

    useEffect(() => {
        (async function () {
            const vbs = await request(`Public/Partners?skip=0&take=${itemsPerPage}&requireTotalCount=true`);
            setTotalCount(vbs.totalCount);
            setItems(vbs.data);
        })();
    }, [])


    // Invoke when user click to request another page.
    const handlePageClick = async (pageNumber) => {
        const newOffset = ((pageNumber - 1) * itemsPerPage) % totalCount;
        const vbs = await request(`Public/Partners?skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true`);
        setActivePage(pageNumber);
        setTotalCount(vbs.totalCount);
        setItems(vbs.data);
    };

    const handleChangePage = (event) => {
        setItemsPerPage(event.target.value);
    };
    return (
        <>
            <div className="breadcrumb">
                <a href="/" className="breadcrumb-item">Trang chủ</a>
                <a href="/trangthanhphan" className="breadcrumb-item">Trang thành phần</a>
            </div>
            <div className="list-partners tleft">
                <div className="list-item">
                    {items && items.map((item) =>
                        <div className="item">
                            <a href={item.URL}>
                                <img src={pathImg(item.AnhBannerUrl)} alt={item.TenBanner} />
                                <span className="text-m">{item.TenBanner}</span>
                            </a>
                        </div>
                    )}
                </div>
            </div>
            <div className="navi-page">
                <Pagination
                    activePage={activePage}
                    itemClass='page-item'
                    linkClass='page-link'
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageClick}
                />
            </div>
        </>
    );
}
