
import React, { useState, useCallback, useRef, useEffect } from 'react';

import Moment from 'react-moment';
import config from '../config';
import { createCustomStore, pathImg, request, truncatedHtml } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxRelate from '../components/Post/BoxRelate';
import { useParams } from 'react-router-dom';
import LayoutHome from '../layouts/LayoutHome';
import BoxSideBar from '../components/Common/BoxSideBar';
import BoxSideBarEn from '../components/Common/BoxSideBarEn';
import Pagination from "react-js-pagination";


export default function ChuyenMucCap2LayoutEn({ chuyenmuc, showsidebar = true }) {
    const params = useParams();

    const [itemsPerPage, setItemsPerPage] = useState(8);
    const [activePage, setActivePage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [items, setItems] = useState();

    useEffect(() => {
        (async function () {
            const vbs = await request(`Public/PostsByCategory?ID=${params.id}&skip=0&take=${itemsPerPage}&requireTotalCount=true`);
            setTotalCount(vbs.totalCount);
            setItems(vbs.data);
        })();
    }, [])


    // Invoke when user click to request another page.
    const handlePageClick = async (pageNumber) => {
        const newOffset = ((pageNumber - 1) * itemsPerPage) % totalCount;
        const vbs = await request(`Public/PostsByCategory?ID=${params.id}&skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true`);
        setTotalCount(vbs.totalCount);
        setActivePage(pageNumber);
        setItems(vbs.data);
    };
    const handleChangePage = (event) => {
        setItemsPerPage(event.target.value);
    };

    return (
        <div className="htvb-box">
            {showsidebar &&
                <BoxSideBarEn idChuyenMuc={chuyenmuc.data.ID} showSame={true} />
            }
            <div className="box-content">
                <div className="list-tt-c3">
                    {items && items.map((item) =>
                        <div className="item">
                            <div className="date-box">
                                <Moment format="MM/DD/YYYY">
                                    {item.NgayXuatBan}
                                </Moment>
                            </div>

                            <div className="text-content">
                                <h2 className="post-title">
                                    <a href={`/topic/${item.ID}`}>{item.TieuDe}</a>
                                </h2>
                                <p className="post-desc" dangerouslySetInnerHTML={{ __html: truncatedHtml(item.MoTa) }}>
                                </p>
                            </div>
                            <a className="post-image">
                                <img src={pathImg(item.AnhDaiDienUrl)} alt={item.AnhDaiDienUrl} />
                            </a>
                        </div>
                    )}
                </div>
                <div className="pagination-bottom">
                    <div className="page-text">
                        <span className="text-m">Number of rows</span>
                        <div className="select-custom">
                            <select title="Number of rows" name="" id="" className="form-control" onChange={handleChangePage}>
                                <option value="">8</option>
                                <option value="">10</option>
                                <option value="">20</option>
                            </select>
                        </div>
                    </div>

                    <Pagination
                        activePage={activePage}
                        itemClass='page-item'
                        linkClass='page-link'
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={5}
                        onChange={handlePageClick}
                    />
                </div>
            </div>
        </div>
    );
}




// import React, { useState, useCallback, useRef } from 'react';

// import config from '../config';
// import { createCustomStore, pathImg, request, truncatedHtml } from '../utils/api';
// import { useTranslation } from 'react-i18next';
// import BoxRelate from '../components/Post/BoxRelate';
// import { useParams } from 'react-router-dom';
// import LayoutHome from '../layouts/LayoutHome';
// import BoxSideBar from '../components/Common/BoxSideBar';
// import BoxNew from '../components/Common/BoxNew';
// import BoxNewEn from '../components/Common/BoxNewEn';
// import BoxSideBarEn from '../components/Common/BoxSideBarEn';

// export default function ChuyenMucCap2LayoutEn({ chuyenmuc }) {
//     return (
//         <>
//         <div className="htvb-box">
//             <BoxSideBarEn idChuyenMuc={chuyenmuc.data.ID} showChild={true} />
//             <div className="box-content">
//                 <div className="top-news-box">
//                     <div className="top-news">
//                         {chuyenmuc.topposts.length > 0 &&
//                             <>
//                                 <div className="hotnews">
//                                     <a className="post-image">
//                                         <img src={pathImg(chuyenmuc.topposts[0].AnhDaiDienUrl)} alt={chuyenmuc.topposts[0].AnhDaiDienUrl} />
//                                     </a>
//                                     <h2 className="post-title">
//                                         <a href={`/topic/${chuyenmuc.topposts[0].ID}`}>{chuyenmuc.topposts[0].TieuDe}</a>
//                                     </h2>
//                                     <p className="post-desc" dangerouslySetInnerHTML={{__html:truncatedHtml(chuyenmuc.topposts[0].MoTa) }}></p>
//                                 </div>
//                             </>
//                         }
//                         <div className="list-news-hot">
//                             {chuyenmuc.topposts.map((item, index) => {
//                                 return (
//                                     <div className="item">
//                                         <a href={`/topic/${item.ID}`} className="image">
//                                             <img src={pathImg(item.AnhDaiDienUrl)} alt={item.AnhDaiDienUrl} />
//                                         </a>
//                                         <a className="text-content" href={`/topic/${item.ID}`}>
//                                             {truncatedHtml(item.TieuDe, 20)}
//                                         </a>
//                                     </div>
//                                 )
//                             })
//                             }
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//         <div className="row">
//             {chuyenmuc.childs.map((item) =>
//                 <BoxNewEn idChuyenmuc={item.ID} className="col-md-4 col-sm-6 col-12" />
//             )}
//         </div>
//         </>
//     );
// }
