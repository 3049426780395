
import React, { useState, useCallback, useRef } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import config from '../config';
import { createCustomStore } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import HeaderEn from '../components/Layout/HeaderEn';

export default function VanBanQPPLEn() {
  const params = useParams();
  const pStyle = {
		display: "block",
		height: "100vh",
		width: "100vw",
    position: "absolute",
    right: "0px"
  };
  return (
    <>
      <HeaderEn
          menuToggleEnabled
      />
      <div className={"main-wrapper"}>
        <div className="container">
          <div class="breadcrumb">
              <a href="/" className="breadcrumb-item">Home</a>
              <a href="/legislation" class="breadcrumb-item">Legislation</a>
          </div>
          <embed src={`https://vbpl.vn/bolaodong/Pages/iHome.aspx?Keyword=${params.keyword?params.keyword:""}`} style={pStyle} height="100%" width="100%" frameBorder="0">
          </embed>
        </div>
      </div>
    </>
  );
}
