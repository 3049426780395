
import React, { useState, useCallback, useRef, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/auth';
import HomeEn from './HomeEn';
import Home from './Home';

export default function TrangChu() {
  const auth = useAuth();
  const { t } = useTranslation();
  return (
    <>
      {auth.language == "vi" && <Home />}
      {auth.language == "en" && <HomeEn />}
    </>
  );
}
