import config from '../config';
import { createCustomStore } from '../utils/api';
import defaultUser from '../utils/default-user';

export async function signIn(email, password, type) {
  try {
    // Send request
    var loginData = {
      grant_type: 'password',
      username: email,
      password: password
    };
    const response = await fetch(`${window.env.REACT_APP_API_URL}/Token?type=${type}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },  
      body: `grant_type=password&username=${email}&password=${password}`
    });
    var data = await response.json();
    // console.log(data);
    if(data.access_token == undefined && data.error == 'invalid_grant'){
      return {
        isOk: false,
        message: data.error_description
      };
    }
    localStorage.setItem("tokenKey", data.access_token);

    const response2 = await fetch(`${window.env.REACT_APP_API_URL}/User_Account/Get?filter=["TenDangKy","=","${email}"]`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${data.access_token}`
      },  
    });
    var data = await response2.json();
    if(data.data.length == 0){
      return {
        isOk: false,
        message: "Authentication failed"
      };
    }
    localStorage.setItem("user", JSON.stringify(data.data[0]));
    return {
      isOk: true,
      data: data.data[0]
    };
  }
  catch (ex){
    console.log(ex);
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function getUser() {
  try {
    let user  = localStorage.getItem("user");
    if(user){
      return {
        isOk: true,
        data: JSON.parse(user)
      };
    }
    return {
      isOk: false,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
