import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import config from '../../config';
import { useAuth } from '../../contexts/auth';
import { pathImg, request, truncatedHtml } from '../../utils/api';
import { Outlet, Link } from "react-router-dom";
import Moment from 'react-moment';
import 'moment/locale/vi';
import parse from 'html-react-parser';
import moment from 'moment';
import BoxSuKien from './BoxSuKien';
import BoxSuKienEn from './BoxSuKienEn';


export default function BoxHotEn({ children }) {
  const auth = useAuth();
  const [danhngons, setDanhngons] = useState();
  const [hots, setHots] = useState();
  useEffect(() => {
    (async function () {
      const data = await request(`Public/DanhNgon?lang=en`);
      const hots = await request(`Public/BoxHotEn`);
      setDanhngons(data);
      setHots(hots);
    })();
  }, [])
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 7000,
  };
  const capitalizeFirstLowercaseRest = str => {
    return (
      str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    );
  };
  return (
    <>
      <div className="datesystem main py-2">
        <Moment locale='en' format="dddd, MMMM Do, YYYY" />
      </div>

      <div className="top-news-box">
        <div className="top-news">
          {hots && hots.data.length > 0 && <div className="hotnews">
            <Link to={`/topic/${hots.data[0].ID}`} className="post-image">
              <img src={pathImg(hots.data[0].AnhDaiDienUrl)} alt={hots.data[0].AnhDaiDienUrl} />
            </Link>
            <h2 className="post-title">
              <a href={`/topic/${hots.data[0].ID}`}>{truncatedHtml(hots.data[0].TieuDe, 20)}</a>
            </h2>
            <p className="post-desc">
              {parse(truncatedHtml(hots.data[0].MoTa))}
            </p>
          </div>
          }
          <div className="list-news-hot">
            {hots && hots.data.map((item, index) => {
              if (index == 0) {
                return;
              }
              return (
                <div className="item">
                  <a href={`/topic/${item.ID}`} className="image">
                    {moment.duration(moment().diff(item.NgayXuatBan)).asDays() <= 2 &&
                      <span class="featured">Tin mới</span>
                    }
                    <img src={pathImg(item.AnhDaiDienUrl)} alt={item.AnhDaiDienUrl} />
                  </a>
                  <a className="text-content" href={`/topic/${item.ID}`}>
                    {truncatedHtml(item.TieuDe, 20)}
                  </a>
                </div>
              )
            }
            )}
          </div>
        </div>
        <div className="pb-tt">
          <div className="box-pb">
            <div className="image">
              <img src="/IMG-0832.jpg" alt="/IMG-0832.jpg" />
            </div>
            <Slider className="list-pb mb-2" {...settings}>
              {danhngons && danhngons.data.map((item) =>
                <div className="item">
                  <div className="mess bold">
                    <sup><span className="isax-quote-up icon"></span></sup>
                    {item.CauNoi}
                    <sup><span className="isax-quote-down icon"></span></sup>
                  </div>
                  <div className="note">{item.TacGia}</div>
                </div>
              )}
            </Slider>
          </div>
          <BoxSuKienEn title='UP-COMING EVENTS' />
        </div>
      </div>
    </>
  )
}
