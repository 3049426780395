import React, { useState, useCallback, useRef, useEffect } from 'react';
import Moment from 'react-moment';
import { useAuth } from '../../contexts/auth';
import { request } from '../../utils/api';


export default function BoxSideBarVBCDDH({ id = null, limit = 5 }) {
    const [vanbans, setVanbans] = useState({ data: [] });
    const auth = useAuth();
    useEffect(() => {
        (async function () {
            const vbs = await request(`Public/VanBanByLopVanBan?id=${id}&limit=${limit}&lang=vi`);
            setVanbans(vbs);
        })();
    }, [])
    return (
        <>
            <div className="sidebar-cat">
                <div className="title">VĂN BẢN CHỈ ĐẠO ĐIỀU HÀNH</div>
                <div className="list-vb-cd p-3">
                    {vanbans.data.map((item) =>
                        <div className="item">
                            <a className="t-title" href={`/vanbanchidao/${item.ID}`}>{item.TrichYeu}</a>
                            <div className="post-date">
                                {item.NgayBanHanh && <Moment format="DD/MM/YYYY">
                                    {item.NgayBanHanh}
                                </Moment>}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
