
import React, { useState, useCallback, useRef, useEffect } from 'react';

import Moment from 'react-moment';
import config from '../config';
import { createCustomStore, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxRelate from '../components/Post/BoxRelate';
import { useParams } from 'react-router-dom';
import LayoutHome from '../layouts/LayoutHome';
import BoxSideBar from '../components/Common/BoxSideBar';
import ReactPaginate from 'react-paginate';
import BoxSideBarVBCDDH from '../components/Common/BoxSideBarVBCDDH';
import { Helmet } from 'react-helmet';


export default function SiteMap({ chuyenmuc }) {
    const params = useParams();
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI-Sitemap`}</title>
            </Helmet>
            <div className="breadcrumb">
                <a href="/" className="breadcrumb-item">Trang chủ</a>
                <a href="/sitemap" className="breadcrumb-item">Sơ đồ site</a>
            </div>
            <div className="row">
                <div className="main-left col-sm-8 col-12">
                    <div className="box-sitemap">
                        <h1 className="title">SƠ ĐỒ SITE</h1>
                        <ul className="sitemap">
                            <li className="item">
                                <a href="/"> TRANG CHỦ</a>
                            </li>
                            <li className="item">
                                <a href="/chucnangnhiemvu"> GIỚI THIỆU</a>
                                <ul>
                                    <li><a href="/cocautochuc">Cơ cấu tổ chức</a></li>
                                    <li><a href="/lanhdaobo">Lãnh đạo bộ</a></li>
                                    <li><a href="/thoikyphattrien">Thời kỳ phát triển</a></li>
                                </ul>
                            </li>
                            <li className="item">
                                <a href="/tintuc"> TIN TỨC</a>
                                <ul>
                                    <li><a href="/chuyenmuc/11536">Tin tổng hợp</a></li>
                                    <li><a href="/chuyenmuc/11537">Tin chuyên ngành</a></li>
                                    <li><a href="/chuyenmuc/26">Địa phương - Cơ sở</a></li>
                                    <li><a href="/chuyenmuc/286">Đảng - Đoàn thể</a></li>
                                    <li><a href="/chuyenmuc/254">Các vấn đề khác</a></li>
                                </ul>
                            </li>
                            <li className="item">
                                <a href="/#"> VĂN BẢN</a>
                                <ul>
                                    <li><a href="/vanbanqppl">Văn bản Quy phạm pháp luật</a></li>
                                    <li><a href="/vanbanchidao">Văn bản Chỉ đạo điều hành</a></li>
                                    <li><a href="/vanbanquocte">Văn bản Hợp tác quốc tế</a></li>
                                </ul>
                            </li>
                            <li className="item">
                                <a href="http://thongke.molisa.gov.vn/"> SỐ LIỆU</a>
                            </li>
                            <li className="item">
                                <a href="http://dvc.molisa.gov.vn/HomePage.aspx"> DỊCH VỤ CÔNG</a>
                            </li>
                            <li className="item">
                                <a href="/chuyenmuc/11534"> EMAGAZINE</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-4 col-12">
                    <div className="box-sidebar">
                        <BoxSideBarVBCDDH id={104} />
                    </div>
                </div>
            </div>
        </>
    );
}
