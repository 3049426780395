
import React, { useState, useCallback, useRef, useEffect } from 'react';

import Moment from 'react-moment';
import config from '../config';
import { createCustomStore, interleave, pathImg, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxRelate from '../components/Post/BoxRelate';
import { useParams } from 'react-router-dom';
import LayoutHome from '../layouts/LayoutHome';
import BoxSideBar from '../components/Common/BoxSideBar';

import DateBox from 'devextreme-react/date-box';
import moment from 'moment';
import Pagination from "react-js-pagination";


export default function NhanSuMoi({ }) {
    const params = useParams();

    const [filterYear, setFilterYear] = useState();
    const [itemsPerPage, setItemsPerPage] = useState(8);
    const [activePage, setActivePage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [nhansu, setNhansu] = useState();
    const [items, setItems] = useState();

    useEffect(() => {
        (async function () {
            reloadData(0);
        })();
    }, [filterYear])


    async function reloadData(newOffset) {
        let filter = [];
        if (filterYear) {
            filter.push(["NgayQDBoNhiem", "contains", filterYear]);
        }
        let filterStr = JSON.stringify(interleave(filter, "and"));
        const vbs = await request(`Public/NhanSuMoi?skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true&filter=${filterStr}`);
        setTotalCount(vbs.totalCount);
        setItems(vbs.data);
    }

    // Invoke when user click to request another page.
    const handlePageClick = async (pageNumber) => {
        const newOffset = ((pageNumber - 1) * itemsPerPage) % totalCount;
        setActivePage(pageNumber);
        reloadData(newOffset);
    };

    const handleChangePage = (event) => {
        setItemsPerPage(event.target.value);
    };
    const loadDetail = async (ID) => {
        const vbs = await request(`Public/ThongTinLanhDaoDetail?ID=${ID}`);
        setNhansu(vbs.data);
    };
    return (
        <>
            <div className="breadcrumb">
                <a href="/" className="breadcrumb-item">Trang chủ</a>
                <a href="/nhansumoi" className="breadcrumb-item">Nhân sự mới</a>
            </div>
            <div className="box-nsmoi">
                <div className="list">
                    {items && items.length == 0 &&
                        <>
                            Không có dữ liệu
                        </>
                    }
                    {items && items.length > 0 && items.map((item) =>
                        <div className="item text-center">
                            <div className="img">
                                <img src={`${pathImg(item.AnhDaiDien)}`} alt={`${pathImg(item.AnhDaiDien)}`} />
                            </div>
                            <p className="mb-1">
                                {item.GioiTinh == 1 ? 'Ông' : ''}
                                {item.GioiTinh == 0 ? 'Bà' : ''}
                            </p>
                            <p className="mb-1 font-weight-bold">{item.HoTenLanhDao}</p>
                            <p className="mb-1">Quyết định: số {item.SoQDBoNhiem} <em>(ngày
                                {item.NgayQDBoNhiem &&
                                    <Moment format="DD/MM/YYYY">
                                        {item.NgayQDBoNhiem}
                                    </Moment>
                                }
                                )</em></p>
                            <p className="mb-1">Ngày bổ nhiệm:
                                {item.NgayQDBoNhiem &&
                                    <Moment format="DD/MM/YYYY">
                                        {item.NgayQDBoNhiem}
                                    </Moment>
                                }
                            </p>
                            <p className="mb-1">Thời hạn: {item.ThoiHanBoNhiem}</p>
                            <p className="mb-1">Chức vụ: {item.ChucVuMoi}</p>
                            <div className="group-buttons">
                                <a className="btn py-2 main underline" href={`${item.LinkQDBoNhiem}`}>Tin tức</a>
                                <button className="btn py-2 main underline" data-toggle="modal" data-target="#modalChitiet" onClick={() => loadDetail(item.ID)}>Chi tiết</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="navi-page">

                <div className="date-word">
                    <DateBox type="date" title="Filter" calendarOptions={{
                        maxZoomLevel: 'decade',
                        minZoomLevel: 'decade',
                    }} displayFormat="yyyy" onValueChanged={(e) => setFilterYear(moment(e.value).year())} />
                </div>

                <Pagination
                    activePage={activePage}
                    itemClass='page-item'
                    linkClass='page-link'
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageClick}
                />
            </div>
            <div className="modal" id="modalChitiet" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{ width: '100%', maxWidth: '991px' }}>
                    <div className="modal-content">
                        <div className="modal-body">
                            {nhansu &&
                                <div className="box-detail-nsm">
                                    <div className="image">
                                        <img src={`${pathImg(nhansu.AnhDaiDien)}`} alt={`${pathImg(nhansu.AnhDaiDien)}`} />
                                    </div>
                                    <div className="text-content">
                                        <div className="title f24 bold">Thông tin chi tiết:</div>
                                        <div className="row">
                                            <div className="col-sm-6 col-12">
                                                <div className="info">
                                                    <span className="-ap icon-dot-single" /> Đồng chí {nhansu.HoTenLanhDao}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-12">
                                                <div className="info">
                                                    Giới tính:&nbsp;
                                                    {nhansu.GioiTinh == 1 ? 'Nam' : ''}
                                                    {nhansu.GioiTinh == 0 ? 'Nữ' : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info">
                                            <span className="-ap icon-dot-single" /> Năm sinh:&nbsp;
                                            {nhansu.NgaySinh && <Moment format="YYYY">
                                                {nhansu.NgaySinh}
                                            </Moment>
                                            }
                                        </div>
                                        <div className="info">
                                            <span className="-ap icon-dot-single" /> Trình độ: {nhansu.TrinhDo}
                                        </div>
                                        <div className="info">
                                            <span className="-ap icon-dot-single" /> Trình độ LLCT: {nhansu.TrinhDoLLCT}
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-12">
                                                <div className="info">
                                                    <span className="-ap icon-dot-single" /> Chức vụ cũ: {nhansu.ChucVuCu}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-12">
                                                <div className="info">
                                                    Đơn vị: <span className="fw5">{nhansu.DonViCu}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-12">
                                                <div className="info">
                                                    <span className="-ap icon-dot-single" /> Chức vụ mới: {nhansu.ChucVuMoi}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-12">
                                                <div className="info">
                                                    Đơn vị: <span className="fw5">{nhansu.DonViMoi}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info ml-3">
                                            {nhansu.ThongTinQĐBoNhiem}
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-12">
                                                <div className="info ml-3">
                                                    Thời hạn bổ nhiệm: {nhansu.ThoiHanBoNhiem}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-12">
                                                <div className="info">
                                                    Bắt đầu:&nbsp;
                                                    <Moment format="DD/MM/YYYY">
                                                        {nhansu.BatDauBoNhiem}
                                                    </Moment>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info">
                                            <span className="-ap icon-dot-single" /> Quá trình công tác:
                                        </div>
                                        {nhansu.QuaTrinhCongTac && nhansu.QuaTrinhCongTac.split(/\r?\n/).map((item) =>
                                            <div className="info ml-3">
                                                {item}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
