import React, { useState, useCallback, useRef, useEffect } from 'react';
import Moment from 'react-moment';
import { useAuth } from '../../contexts/auth';
import { request } from '../../utils/api';


export default function BoxHighLightEn({ id = null, limit = 5 }) {
    const [items, setItems] = useState({ data: [] });
    const auth = useAuth();
    useEffect(() => {
        (async function () {
            const vbs = await request(`Public/BoxHighLight?limit=${limit}&lang=en`);
            setItems(vbs);
        })();
    }, [])
    return (
        <>
            <div className="sidebar-cat">
                <div className="title">HIGHLIGHTS</div>
                <div className="list-vb-cd p-3">
                    {items.data.map((item) =>
                        <div className="item">
                            <a className="t-title" href={`/topic/${item.ID}`}>{item.TieuDe}</a>
                            <div className="post-date">
                                {item.NgayXuatBan && <Moment format="MM/DD/YYYY">
                                    {item.NgayXuatBan}
                                </Moment>}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
