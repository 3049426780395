import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import config from '../../config';
import parse from 'html-react-parser';
import { pathImg, request, truncatedHtml } from '../../utils/api';
import Pagination from "react-js-pagination";
export default function BoxRelate({ id }) {

    const [itemsPerPage, setItemsPerPage] = useState(6);
    const [activePage, setActivePage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [items, setItems] = useState();
    useEffect(() => {
        (async function () {
            const vbs = await request(`Public/PostRelate?ID=${id}&lang=vi&skip=0&take=${itemsPerPage}&requireTotalCount=true`);
            setTotalCount(vbs.totalCount);
            setItems(vbs.data);
        })();
    }, [])

    // Invoke when user click to request another page.
    const handlePageClick = async (pageNumber) => {
        const newOffset = ((pageNumber - 1) * itemsPerPage) % totalCount;
        const vbs = await request(`Public/PostRelate?ID=${id}&lang=vi&skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true`);
        setActivePage(pageNumber);
        setTotalCount(vbs.totalCount);
        setItems(vbs.data);
    };
    const handleChangePage = (event) => {
        setItemsPerPage(event.target.value);
    };
    return (
        <>

            <h2 className="title-related">
                <span className="icon">
                    <img src="/assets/images/news.svg" alt="news.svg" />
                </span>
                <span className="text-m f18">TIN KHÁC</span>
            </h2>
            <div className="list-tt-c3">

                {items && items.map((item) =>
                    <div className="item" key={item.ID}>

                        <a href={`/baiviet/${item.ID}`} className="post-image">
                            <img src={pathImg(item.AnhDaiDienUrl)} alt={item.AnhDaiDienUrl} />
                        </a>
                        <div className="text-content">
                            <h2 className="post-title">
                                <a href={`/baiviet/${item.ID}`}>{item.TieuDe}</a>
                            </h2>
                            <p className="post-desc">
                                {parse(truncatedHtml(item.MoTa))}
                            </p>
                            <div className="post-date f14">
                                <Moment format="DD/MM/YYYY">
                                    {item.NgayXuatBan}
                                </Moment>
                            </div>
                        </div>

                    </div>
                )}
            </div>
            <div className="pagination-bottom">
                <div className="page-text">
                    <span className="text-m">Số tin mỗi trang</span>
                    <div className="select-custom">
                        <select title="Số tin mỗi trang" name="" id="" className="form-control" onChange={handleChangePage}>
                            <option value="6">6</option>
                            <option value="8">8</option>
                            <option value="10">10</option>
                        </select>
                    </div>
                </div>

                <Pagination
                    activePage={activePage}
                    itemClass='page-item'
                    linkClass='page-link'
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageClick}
                />
            </div>
        </>
    )
}
