import React, { useState, useCallback, useRef, useEffect } from 'react';

export default function BoxSideBarCoCauToChucEn({ tochucs }) {
  let head = tochucs.filter(x => x.IsHead == 1);
  let childs = tochucs.filter(x => x.IsHead == 0);
  return (
    <>
      {tochucs &&
        <div className="box-sidebar">
          <div className="head text-center f12">
            {head.length > 0 && head[0].TenDonVi.toUpperCase()}
          </div>
          <div className="body">
            <div className="list-vb-cd p-3">
              {childs.map((item, index) =>
                <div className="item">
                  <a className="t-title" href={`/organizational/${item.ID}`}>{item.TenDonVi}</a>
                </div>
              )
              }
            </div>
          </div>
        </div>
      }
    </>
  )
}
