import { pathImg } from "./utils/api";

let config = {

    defaultImageCellRender: (data) => {
        return (data.value && <img src={pathImg(data.value)} alt={window.env.REACT_APP_UPLOAD_URL + data.value} className="img-fluid" />);
    },
    htmlEditorOptions: {
        height: 250,

        imageUpload: {
            tabs: ['file', 'url'],
            fileUploadMode: 'base64',
        },
        toolbar: {
            items: [
                'undo', 'redo', 'separator',
                {
                    name: 'size',
                    options: { value: '14px' },
                    acceptedValues: ['8px', '10px', '12px', '14px', '18px', '24px', '36px'],
                },
                {
                    name: 'font',
                    acceptedValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'],
                },
                'separator', 'bold', 'italic', 'strike', 'underline', 'separator',
                'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'separator',
                'orderedList', 'bulletList', 'separator',
                {
                    name: 'header',
                    acceptedValues: [false, 1, 2, 3, 4, 5],
                }, 'separator',
                'color', 'background', 'separator',
                'link', 'image', 'separator',
                'clear', 'codeBlock', 'blockquote', 'separator',
                'insertTable', 'deleteTable',
                'insertRowAbove', 'insertRowBelow', 'deleteRow',
                'insertColumnLeft', 'insertColumnRight', 'deleteColumn', 'cellProperties', 'tableProperties'
            ],
        },
    }
};

export default Object.freeze(Object.assign({}, config));