
import React, { useState, useCallback, useRef, useEffect } from 'react';

import config from '../config';
import { createCustomStore, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxRelate from '../components/Post/BoxRelate';
import { useParams } from 'react-router-dom';
import LayoutHome from '../layouts/LayoutHome';
import CategoryLevel1 from './ChuyenMucCap1Layout';
import CategoryLevel2 from './ChuyenMucCap2Layout';
import CategoryLevel3 from './ChuyenMucCap3Layout';
import { Footer, Header } from '../components';
import Emagazine from './Emagazine';
import EmagazineEn from './EmagazineEn';
import HeaderEn from '../components/Layout/HeaderEn';
import FooterEn from '../components/Layout/FooterEn';
import ChuyenMucCap1LayoutEn from './ChuyenMucCap1LayoutEn';
import ChuyenMucCap2LayoutEn from './ChuyenMucCap2LayoutEn';
import ChuyenMucCap3LayoutEn from './ChuyenMucCap3LayoutEn';
import ChuyenMucTongHopEnLayout from './ChuyenMucTongHopEnLayout';
import Breadcrumb from '../components/Common/BreadCrumb';
import { Helmet } from 'react-helmet';


export default function ChuyenMucEn() {
    const params = useParams();
    const [chuyenmuc, setChuyenmuc] = useState();
    useEffect(() => {
        (async function () {
            const vbs = await request(`Public/ChuyenMucDetail?ID=${params.id}`);
            setChuyenmuc(vbs);
        })();
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`MINISTRY OF LABOUR - INVALIDS AND SOCIAL AFFAIRS-${chuyenmuc && chuyenmuc.data.TenChuyenMuc}`}</title>
            </Helmet>
            {chuyenmuc && chuyenmuc.data.Type == 3 &&
                <EmagazineEn chuyenmuc={chuyenmuc} />
            }
            {chuyenmuc && chuyenmuc.data.Type == 4 &&
                <>
                    <HeaderEn
                        menuToggleEnabled
                    />
                    <div className={"main-wrapper"}>
                        <div className="container">
                            <Breadcrumb>
                                <a href="/" className="breadcrumb-item">Home</a>
                                <a href="/news" className="breadcrumb-item">News</a>
                                {chuyenmuc && chuyenmuc.parents &&
                                    chuyenmuc.parents.map((item, index) => {
                                        if (index == 0) {
                                            return;
                                        }
                                        return (
                                            <a href={`/category/${item.ID}`} className="breadcrumb-item">{item.TenChuyenMuc}</a>
                                        )
                                    }
                                    )
                                }
                            </Breadcrumb>
                            <h1 className="title-main main bold f18 mb-4 text-uppercase">{chuyenmuc && chuyenmuc.data.TenChuyenMuc}</h1>
                            <ChuyenMucTongHopEnLayout chuyenmuc={chuyenmuc} />
                        </div>
                    </div>
                    <FooterEn />
                </>
            }
            {chuyenmuc && chuyenmuc.data.Type == 5 &&
                <>
                    <HeaderEn
                        menuToggleEnabled
                    />
                    <div className={"main-wrapper"}>
                        <div className="container">
                            <Breadcrumb>
                                <a href="/" className="breadcrumb-item">Home</a>
                                {chuyenmuc && chuyenmuc.parents &&
                                    chuyenmuc.parents.map((item, index) => {
                                        if (index == 0) {
                                            return;
                                        }
                                        return (
                                            <a href={`/category/${item.ID}`} className="breadcrumb-item">{item.TenChuyenMuc}</a>
                                        )
                                    }
                                    )
                                }
                            </Breadcrumb>
                            <h1 className="title-main main bold f18 mb-4 text-uppercase">{chuyenmuc && chuyenmuc.data.TenChuyenMuc}</h1>
                            <ChuyenMucCap3LayoutEn chuyenmuc={chuyenmuc} />
                        </div>
                    </div>
                    <FooterEn />
                </>
            }
            {chuyenmuc && [3, 4, 5].indexOf(chuyenmuc.data.Type) == -1 &&
                <>
                    <HeaderEn
                        menuToggleEnabled
                    />
                    <div className={"main-wrapper"}>
                        <div className="container">
                            <Breadcrumb>
                                <a href="/" className="breadcrumb-item">Home</a>
                                <a href="/news" className="breadcrumb-item">News</a>
                                {chuyenmuc && chuyenmuc.parents &&
                                    chuyenmuc.parents.map((item, index) => {
                                        if (index == 0) {
                                            return;
                                        }
                                        return (
                                            <a href={`/category/${item.ID}`} className="breadcrumb-item">{item.TenChuyenMuc}</a>
                                        )
                                    }
                                    )
                                }
                            </Breadcrumb>
                            <h1 className="title-main main bold f18 mb-4 text-uppercase">{chuyenmuc && chuyenmuc.data.TenChuyenMuc}</h1>
                            {chuyenmuc && chuyenmuc.parents.length == 2 &&
                                <ChuyenMucCap1LayoutEn chuyenmuc={chuyenmuc} />
                            }
                            {chuyenmuc && chuyenmuc.parents.length == 3 &&
                                <ChuyenMucCap2LayoutEn chuyenmuc={chuyenmuc} />
                            }
                            {chuyenmuc && chuyenmuc.parents.length != 2 && chuyenmuc.parents.length != 3 &&
                                <ChuyenMucCap3LayoutEn chuyenmuc={chuyenmuc} />
                            }
                        </div>
                    </div>
                    <FooterEn />

                </>
            }
        </>
    );
}
