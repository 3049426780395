
import React, { useState, useCallback, useRef, useEffect } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import config from '../config';
import { createCustomStore, interleave, pathFile, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useParams, useSearchParams } from 'react-router-dom';
import Moment from 'react-moment';
import { LoadPanel } from 'devextreme-react';

export default function DuThaoVanBan() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [banners, setBanners] = useState();
  const [vanban, setVanban] = useState();
  const [lopvanbans, setLopvanbans] = useState();
  const [page, setPage] = useState(1);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [items, setItems] = useState([]);
  const searchForm = useRef();
  const id = params.id;
  const LopVanBanID = searchParams.get("LopVanBanID");

  useEffect(() => {
    (async function () {
      const bans = await request(`/Public/BannerByViTri?ID_ViTri=3210`);
      setBanners(bans);
      const lopvanbans = await request(`Public/LopVanBan?filter=["LopVanBanCha","=","105"]`);
      setLopvanbans(lopvanbans);

      if (id) {
        const bans = await request(`/Public/VanBanDetail?ID=${id}`);
        if (bans.data != null) {
          setVanban(bans);
        }
      }
    })();
  }, [])

  useEffect(() => {
    if(lopvanbans){
      reloadData(0);
    }
  }, [lopvanbans])

  useEffect(() => {
    (async function () {
      if(page > pageCount){
        return;
      }
      const newOffset = totalCount == 0? 0:((page-1) * itemsPerPage) % totalCount;
      reloadData(newOffset);
    })();
  }, [page]);

  async function reloadData(newOffset) {
    setLoading(true);

    let filter = getFilters();
    let filterStr = JSON.stringify(interleave(filter, "and"));
    const vbs = await request(`Public/VanBan?skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true&filter=${filterStr}`);
    setPageCount(Math.ceil(vbs.totalCount / itemsPerPage));
    setTotalCount(vbs.totalCount);
    setItems([...items,...vbs.data]);

    setLoading(false);
  }

  const getFilters = () => {
    let form = searchForm.current;
    setVanban();
    let filter = [];
    filter.push(["LopVanBanID", "=", "105"]);
    // if (form.keyword.value) {
    //   filter.push(["TrichYeu", "contains", form.keyword.value]);
    // }
    // if (form.fromdateNGN.value) {
    //   filter.push(["NgayGiaNhap", ">=", form.fromdateNGN.value]);
    // }
    // if (form.todateNGN.value) {
    //   filter.push(["NgayGiaNhap", "<=", form.todateNGN.value]);
    // }
    // if (form.lopvanban.value) {
    //   filter.push(["LopVanBanID", "=", form.lopvanban.value]);
    // }
    return filter;
  }
  const handleSearch = async (event) => {
    event.preventDefault();
    reloadData(0);
  }

  return (
    <>
      <LoadPanel visible={loading} />
      <div className="breadcrumb">
        <a href="/" className="breadcrumb-item">Trang chủ</a>
        <a href="/duthaovanban" class="breadcrumb-item">Dự thảo văn bản</a>
        {vanban &&
          <a href={`/duthaovanban/${vanban.data.ID}`} class="breadcrumb-item">{vanban.data.SoKyHieu}</a>
        }
      </div>
      <div className='row'>
        {!vanban &&
          <div className="col-sm-8 col-12">
            <div className="box-dtvb">
                <form ref={searchForm} onSubmit={handleSearch}>
                  <div className="box-form" style={{background: '#f6f7f8', padding: '20px', border: '1px solid #e6e7e8', marginBottom: '20px', borderRadius: '8px'}}>
                  <div className="row">
                      <div className="col-sm-6 col-12">
                      <div className="form-group">
                          <div className="label-text">Lĩnh vực văn bản</div>
                          <div className="input">
                          <div className="select-custom">
                              <select name id className="form-control">
                              <option value />
                              </select>
                          </div>
                          </div>
                      </div>
                      </div>
                      <div className="col-sm-6 col-12">
                      <div className="form-group">
                          <div className="label-text">Loại văn bản</div>
                          <div className="select-custom input">
                          <select name id className="form-control">
                              <option value />
                          </select>
                          </div>
                      </div>
                      </div>
                      <div className="col-sm-6 col-12">
                      <div className="form-group">
                          <div className="label-text">Năm phát hành</div>
                          <div className="input-icon-right">
                          <input type="date" className="form-control" />
                          <span className="icon isax-calendar-21" />
                          </div>
                      </div>
                      </div>
                      <div className="col-sm-6 col-12">
                      <div className="form-group">
                          <div className="label-text">đến</div>
                          <div className="input-icon-right">
                          <input type="date" className="form-control" />
                          <span className="icon isax-calendar-21" />
                          </div>
                      </div>
                      </div>
                      <div className="col-sm-12 col-12">
                      <div className="form-group">
                          <div className="label-text">Nội dung văn bản</div>
                          <div className="input">
                          <input type="text" className="form-control" />
                          </div>
                      </div>
                      </div>
                  </div>
                  <div className="form-group">
                      <div className="label-text" />
                      <div className="input">
                      <button className="btn btn-danger">
                          <span className="isax-search-normal1" />
                          Tìm kiếm
                      </button>
                      </div>
                  </div>
                  </div>
                </form>
                <div className="nav nav-tabs">
                <a href="#tabab1" className="nav-link active" data-toggle="tab">VĂN BẢN PHÁP LUẬT</a>
                </div>
                <div className="tab-content">
                    <div className="list-dtvb">
                    {items && items.map((item, index) =>
                        <div className="top-detail item">
                            <div className="featured">
                                Đang lấy ý kiến của Bộ
                            </div>
                            <div className="icon isax-document-text-11">
                            </div>
                            <div className="text-content">
                                <div className="info">
                                <div className="top-date">
                                    <div className="post-date">Ngày bắt đầu lấy ý kiến: 09/04/2023</div>
                                    <div className="post-date">Ngày hết hạn: 08/06/2023</div>
                                </div>
                                </div>
                                <h3 className="post-title"><a href={`/duthaovanban/${item.ID}`} className="f16">Quy định về quản lý, vận hành và sử dụng hệ thống thông tin bảo
                                    trợ xã hội và giảm nghèo</a></h3>
                                <div className="info"><span className="ic isax-category1" /> Lĩnh vực văn bản: Bảo trợ xã hội</div>
                                <div className="info"> <span className="ic isax-document1" /> Loại văn bản: Thông tư</div>
                            </div>
                        </div>
                    )}
                    </div>
                    <div className="actions">
                        <button type='button' className="btn block fullw py-3 text-desc" onClick={() => setPage(page + 1)}>BẤM ĐỂ XEM TIẾP</button>
                    </div>
                </div>
            </div>
          </div>
        }
        {vanban &&
          <>
            <div className="col-sm-8 col-12">
                <div className="box-dtvb">
                <form ref={searchForm} onSubmit={handleSearch}>
                  <div className="box-form" style={{background: '#f6f7f8', padding: '20px', border: '1px solid #e6e7e8', marginBottom: '20px', borderRadius: '8px'}}>
                      <div className="input-buttons">
                      <input className="form-control" id="Keywords" name="Keywords" placeholder="Nhập nội dung văn bản cần tìm..." type="text" defaultValue />
                      <button className="btn btn-danger">
                          <span className="isax-search-normal1" />
                          Tìm kiếm
                      </button>
                      </div>
                  </div>
                </form>
                <div className="nav nav-tabs">
                    <a href="#tabab1" className="nav-link active" data-toggle="tab">Tóm tắt</a>
                    <a href="#tabab2" className="nav-link" data-toggle="tab">Toàn văn dự thảo</a>
                    <a href="#tabab3" className="nav-link" data-toggle="tab">Hồ sơ dự án</a>
                    <a href="#tabab3" className="nav-link" data-toggle="tab">Văn bản liên quan</a>
                    <a href="#tabab3" className="nav-link" data-toggle="tab">Ý kiến người dân</a>
                    <a href="#tabab3" className="nav-link" data-toggle="tab">Ý kiến chuyên gia</a>
                </div>
                <div className="tab-content">
                    <div className="tab-pane active" id="tabab1">
                    <div className="top-detail">
                        <div className="featured">
                        Đang lấy ý kiến của Bộ
                        </div>
                        <div className="icon isax-document-text-11">
                        </div>
                        <div className="text-content">
                        <div className="info">
                            <div className="top-date">
                            <div className="post-date">Ngày bắt đầu lấy ý kiến: 09/04/2023</div>
                            <div className="post-date">Ngày hết hạn: 08/06/2023</div>
                            </div>
                        </div>
                        <h3 className="post-title"><a href="#" className="f16">Quy định về quản lý, vận hành và sử dụng hệ thống thông tin bảo trợ xã hội và giảm nghèo</a></h3>
                        <div className="info"><span className="ic isax-category1" />  Lĩnh vực văn bản: Bảo trợ xã hội</div>
                        <div className="info"> <span className="ic isax-document1" /> Loại văn bản: Thông tư</div>
                        </div>
                    </div>
                    <div className="article p-4">
                        <h3>Tóm tắt</h3>
                        <p>Ban hành Thông tư quy định về quản lý, vận hành và sử dụng hệ thống thông tin bảo trợ xã hội và giảm nghèo.</p>
                    </div>
                    </div>
                    <div className="tab-pane" id="tabab2">
                    <div className="article p-4">
                        <h3>Tóm tắt</h3>
                        <p>Ban hành Thông tư quy định về quản lý, vận hành và sử dụng hệ thống thông tin bảo trợ xã hội và giảm nghèo.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
          </>
        }
        <div className="col-sm-4 col-12">
        <div className="box-sidebar">
          <div className="head">Ý KIẾN NỔI BẬT</div>
          <div className="body p-3">
            <div className="item">
              <div className="post-title">
                <a href="#">Công ty Ô tô Toyota Việt Nam</a>
              </div>
              <div className="post-desc mb-1">* Nội dung vướng mắc: Thời gian huấn luyện đào tạo cho các khóa học an toàn hiện tại đang rất dài .. <a href="#">Xem tiếp</a></div>
              <div className="post-date">
                <span className="isax-clock-11" /> 05/04/2023
              </div>
            </div>
            <div className="divider-gray my-2" />
            <div className="item">
              <div className="post-title">
                <a href="#">Đỗ Khắc Quý</a>
              </div>
              <div className="post-desc mb-1">Đồng ý PÁ2 vì NLĐ phải kéo dài tuổi nghỉ hưu mà vẫn đóng BH cho đến khi được nghỉ hưu theo lộ trình ...<a href="#">Xem tiếp</a></div>
              <div className="post-date">
                <span className="isax-clock-11" /> 05/04/2023
              </div>
            </div>
            <div className="divider-gray my-2" />
            <div className="item">
              <div className="post-title">
                <a href="#">Công ty Ô tô Toyota Việt Nam</a>
              </div>
              <div className="post-desc mb-1">* Nội dung vướng mắc: Thời gian huấn luyện đào tạo cho các khóa học an toàn hiện tại đang rất dài .. <a href="#">Xem tiếp</a></div>
              <div className="post-date">
                <span className="isax-clock-11" /> 05/04/2023
              </div>
            </div>
            <div className="divider-gray my-2" />
            <div className="item">
              <div className="post-title">
                <a href="#">Công ty Ô tô Toyota Việt Nam</a>
              </div>
              <div className="post-desc mb-1">* Nội dung vướng mắc: Thời gian huấn luyện đào tạo cho các khóa học an toàn hiện tại đang rất dài .. <a href="#">Xem tiếp</a></div>
              <div className="post-date">
                <span className="isax-clock-11" /> 05/04/2023
              </div>
            </div>
            <div className="divider-gray my-2" />
            <div className="item">
              <div className="post-title">
                <a href="#">Công ty Ô tô Toyota Việt Nam</a>
              </div>
              <div className="post-desc mb-1">* Nội dung vướng mắc: Thời gian huấn luyện đào tạo cho các khóa học an toàn hiện tại đang rất dài .. <a href="#">Xem tiếp</a></div>
              <div className="post-date">
                <span className="isax-clock-11" /> 05/04/2023
              </div>
            </div>
          </div>
        </div>
        <div className="box-sidebar">
          <div className="head">TIN TỨC LIÊN QUAN</div>
          <div className="body p-3">
            <div className="list-style-ar mb-3">
              <a className="item" href="#">
                Mức xử phạt đối với trung tâm dịch vụ việc làm không theo dõi tình trạng việc làm cho người lao ...
              </a>
              <a className="item" href="#">
                Quy định về ngày công tính lương
              </a>
              <a className="item" href="#">
                Quy định về thời gian hưởng chế độ khi khám thai
              </a>
            </div>
          </div>
        </div>
        <div className="box-sidebar">
          <div className="head">GÓP Ý CHO DỰ THẢO</div>
          <div className="body p-3">
            <div className="form-group">
              <div className="label-text">Tên cá nhân/tổ chức 1</div>
              <input type="text" className="form-control" placeholder />
            </div>
            <div className="form-group">
              <div className="label-text">Email</div>
              <input type="text" className="form-control" placeholder />
            </div>
            <div className="form-group">
              <div className="label-text">Điện thoại</div>
              <input type="text" className="form-control" placeholder />
            </div>
            <div className="form-group">
              <div className="label-text">Địa chỉ</div>
              <input type="text" className="form-control" placeholder />
            </div>
            <div className="form-group">
              <div className="label-text">Nội dung</div>
              <textarea style={{height: '120px', resize: 'none'}} className="form-control" defaultValue={""} />
            </div>
            <button className="btn btn-primary" style={{minWidth: '120px'}}> Gửi</button>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}
