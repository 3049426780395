
import React, { useState, useCallback, useRef, useEffect } from 'react';

import { Footer, Header } from '../components';
import LayoutHome from '../layouts/LayoutHome';
import BoxRelate from '../components/Post/BoxRelate';
import BoxSideBarNav from '../components/Common/BoxSideBarNav';
import Breadcrumb from '../components/Common/BreadCrumb';
import Moment from 'react-moment';
import Notify from 'devextreme/ui/notify';
import BoxRelateEn from '../components/Post/BoxRelateEn';
import BoxSideBarNavEn from '../components/Common/BoxSideBarNavEn';
import FooterEn from '../components/Layout/FooterEn';
import BoxSideBarEn from '../components/Common/BoxSideBarEn';
import HeaderEn from '../components/Layout/HeaderEn';


export default function TinMacDinhLayoutEn({ post}) {
  const sendForm = useRef();
  const userAgent = navigator.userAgent;
  const isSafari = /^((?!Chrome|Android).)*Safari/i.test(userAgent);
  const safariVersion = isSafari ? (userAgent.match(/Version\/([0-9.]+)/) || [])[1] : undefined;
  useEffect(() => {
      (async function () {
        if(!isSafari || safariVersion > 12){
            window.reloadZalo();
        }
      })();
  }, [post])
  const changeFont = (val) => {
      let curVal = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('font-size').replace("px"));
      document.body.style.fontSize = `${curVal + val}px`;
  }
  const handlePrint = () => {
      //console.log('print');  
      let printContents = document.getElementById('articleForPrint').innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      window.location.reload();
  }
  const handleSend = async (event) => {
      event.preventDefault();

      let form = sendForm.current;
      const response = await fetch(`${window.env.REACT_APP_API_URL}/Public/SendMail`, {
          method: 'POST',
          headers: {
              "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `fromUser=${form.fromUser.value}&toUser=${form.toUser.value}&fromEmail=${form.fromEmail.value}&toEmail=${form.toEmail.value}&title=${form.title.value}&address=${form.address.value}&content=${form.content.value}`
      });

      Notify({
          message: `Thành công gửi email`,
          type: 'success',
          displayTime: 3500,
      });
  }

  return (
    <>
        <HeaderEn
            menuToggleEnabled
        />
        <div className={"main-wrapper"}>
            <div className="container">
                <Breadcrumb>
                    <a href="/" className="breadcrumb-item">Home</a>
                    <a href="/news" className="breadcrumb-item">News</a>
                    {post.parents && post.parents.map((item, index) => {
                        if (index == 0) {
                            return;
                        }
                        return (
                            <a href={`/category/${item.ID}`} className="breadcrumb-item">{item.TenChuyenMuc}</a>
                        )
                    }
                    )}
                </Breadcrumb>
                <div className="htvb-box">
                    <div className="box-content">
                        <>
                            <div className="article">
                                <h1 className="title-news f26">
                                    {post.data.TieuDe}
                                </h1>
                                <div className="list-actions-article">
                                    {/* <audio style={{ width: "100%" }} src={`${window.env.REACT_APP_API_URL}/Public/TTS?ID=${post.data.ID}`} controls>
                                        <p>Your browser does not support the <code>audio</code> element.</p>
                                    </audio> */}
                                    <div className="group-buttons">
                                        <div className="fontsize-actions mb-0">
                                            {post.data.NgayXuatBan &&
                                                <>
                                                    <span className="text-m">
                                                        <Moment format="MM/DD/YYYY">
                                                            {post.data.NgayXuatBan}
                                                        </Moment>
                                                    </span>
                                                </>
                                            }
                                            <span className="text-m">View by font size:</span>
                                            <button className="zoom-in" onClick={() => changeFont(1)}>A</button>
                                            <button className="zoom-out" onClick={() => changeFont(-1)}>A</button>
                                        </div>
                                        {/* <button className="btn btn-second btn-sm">
                                            <span className="icon isax-textalign-justifycenter1"></span> Acronyms
                                        </button> */}
                                        <button className="btn btn-second btn-sm" data-toggle="modal" data-target="#modalChitietin">
                                            <span className="icon isax-printer1"></span> <span className="d-none">Print</span>
                                        </button>
                                        {/* <button className="btn btn-second btn-sm" data-toggle="modal" data-target="#modalGuiToiBan">
                                            <span className="icon isax-sms-tracking1"></span> Send
                                        </button> */}
                                    </div>
                                    <div className="social">
                                        <div className="zalo-share-button" style={{ margin: '0px' }} data-href={`http://molisa.gov.vn/topic/${post.data.ID}`} data-oaid="2381586077622143744" data-layout="3" data-color="white" data-share-type="1" data-customize="true" >
                                            <img src="/assets/images/social/zalo.png" alt="zalo.png" />
                                        </div>
                                        <a href={`https://www.facebook.com/sharer/sharer.php?u=http://molisa.gov.vn/topic/${post.data.ID}&quote=${post.data.TieuDe}`}>
                                            <img src="/assets/images/social/fb.png" alt="fb.png" />
                                        </a>
                                        {/* <a href={`https://twitter.com/intent/tweet/?url=http://molisa.gov.vn/topic/${post.data.ID}`}>
                                            <img src="/assets/images/social/yt.png" alt="yt.png" />
                                        </a> */}
                                    </div>
                                </div>
                                <span className="float-left"><strong>(Molisa.gov.vn)&nbsp;-&nbsp;</strong></span>
                                <p>
                                    <strong dangerouslySetInnerHTML={{ __html: post.data.MoTa }}></strong>
                                </p>
                                <div dangerouslySetInnerHTML={{ __html: post.data.NoiDung }} />
                                <div className='clearfix'>
                                    <a className='float-right' href={`https://api.qrserver.com/v1/create-qr-code/?size=60x60&data=${window.location.href}`}>
                                        <img src={`https://api.qrserver.com/v1/create-qr-code/?size=60x60&data=${window.location.href}`} alt="ncsc.png" />
                                    </a>
                                </div>
                            </div>
                            <div id='articleForPrint' className='d-none'>
                                <h1 className="title-news">
                                    {post.data.TieuDe}
                                </h1>
                                <div className="post-date f14">
                                    {post.data.NgayXuatBan && <Moment format="MM/DD/YYYY">
                                        {post.data.NgayXuatBan}
                                    </Moment>
                                    }
                                </div>
                                <span className="float-left"><strong>(Molisa.gov.vn)&nbsp;-&nbsp;</strong></span>
                                <p>
                                    <strong dangerouslySetInnerHTML={{ __html: post.data.MoTa }}></strong>
                                </p>
                                <div dangerouslySetInnerHTML={{ __html: post.data.NoiDung }} />
                                <div className='clearfix'>
                                    <a className='float-right' href={`https://api.qrserver.com/v1/create-qr-code/?size=60x60&data=${window.location.href}`}>
                                        <img src={`https://api.qrserver.com/v1/create-qr-code/?size=60x60&data=${window.location.href}`} alt="ncsc.png" />
                                    </a>
                                </div>
                            </div>
                            <div className="divider-gray"></div>
                            <BoxRelateEn id={post.data.ID} />
                        </>
                    </div>
                    <BoxSideBarEn idChuyenMuc={post.data.IDChuyenMuc} showSame={true} />
                </div>
                <div className="modal" id="modalChitietin" aria-modal="true" role="dialog">
                    <div className="modal-dialog" role="document" style={{ width: '100%', maxWidth: '1200px' }}>
                        <div className="modal-content">
                            <div className="modal-body px-4">
                                <div className="list-actions-article">
                                    <div className="post-date italic">Molisa.gov.vn</div>
                                    <div className="group-buttons">
                                        <div className="fontsize-actions mb-0">
                                            <span className="text-m">View by font size:</span>
                                            <button className="zoom-in" onClick={() => changeFont(1)}>A</button>
                                            <button className="zoom-out" onClick={() => changeFont(-1)}>A</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="article">
                                    <h1 className="title-news">
                                        {post.data.TieuDe}
                                    </h1>
                                    <div className="post-date f14">
                                        {post.data.NgayXuatBan && <Moment format="MM/DD/YYYY">
                                            {post.data.NgayXuatBan}
                                        </Moment>
                                        }
                                    </div>
                                    <span className="float-left"><strong>(Molisa.gov.vn)&nbsp;-&nbsp;</strong></span>
                                    <p><strong dangerouslySetInnerHTML={{ __html: post.data.MoTa }}></strong></p>
                                    <div dangerouslySetInnerHTML={{ __html: post.data.NoiDung }} />
                                    <div className='clearfix'>
                                        <a className='float-right' href={`https://api.qrserver.com/v1/create-qr-code/?size=60x60&data=${window.location.href}`}>
                                            <img src={`https://api.qrserver.com/v1/create-qr-code/?size=60x60&data=${window.location.href}`} alt="ncsc.png" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn fullw upper main fw6 " onClick={handlePrint}>
                                    <span className="isax-printer1 f32" /> PRINT
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="modalGuiToiBan" aria-modal="true" role="dialog">
                    <div className="modal-dialog" role="document" style={{ width: '100%', maxWidth: '1200px' }}>
                        <div className="modal-content">
                            <form ref={sendForm} onSubmit={handleSend}>
                                <div className="modal-body px-4">
                                    <h1 className="title-main main f24" style={{ marginTop: '20px' }}>
                                        SEND INFORMATION
                                    </h1>
                                    <div className="qa-canvas">
                                        <div className='row'>
                                            <div className='col'>
                                                <div className="form-group">
                                                    <label className="label-text" htmlFor="fromUser">From: <span className="red">*</span></label>
                                                    <input type="text" className="form-control" id="fromUser" />
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className="form-group">
                                                    <label className="label-text" htmlFor="fromEmail">Email from: <span className="red">*</span></label>
                                                    <input type="text" className="form-control" id="fromEmail" />
                                                </div>
                                            </div>

                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <div className="form-group">
                                                    <label className="label-text" htmlFor="toUser">To: <span className="red">*</span></label>
                                                    <input type="text" className="form-control" id="toUser" />
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className="form-group">
                                                    <label className="label-text" htmlFor="toEmail">Email to: <span className="red">*</span></label>
                                                    <input type="text" className="form-control" id="toEmail" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="label-text" htmlFor="title">Title: <span className="red">*</span></label>
                                            <input type="text" className="form-control" id="title" />
                                        </div>
                                        <div className="form-group">
                                            <label className="label-text" htmlFor="address">Address:</label>
                                            <input type="text" readOnly={true} className="form-control" id="address" value={`/topic/${post.data.ID}`} />
                                        </div>
                                        <div className="form-group">
                                            <label className="label-text" htmlFor="content">Content: <span className="red">*</span></label>
                                            <textarea name id="content" cols={30} rows={3} className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type='submit' className="btn btn-submit btn-primary">
                                        Send
                                    </button>
                                    <button className="btn btn-submit btn-primary" data-dismiss="modal">
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterEn />
    </>
  );
}
