
import React, { useState, useCallback, useRef, useEffect } from 'react';

import Moment from 'react-moment';
import config from '../config';
import { createCustomStore, pathFile, pathImg, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxRelate from '../components/Post/BoxRelate';
import { useParams } from 'react-router-dom';
import LayoutHome from '../layouts/LayoutHome';
import BoxSideBar from '../components/Common/BoxSideBar';
import BoxSideBarEn from '../components/Common/BoxSideBarEn';
import parse from 'html-react-parser';
import { findAll } from "highlight-words-core";
import Pagination from "react-js-pagination";


export default function TimKiemEn({ chuyenmuc }) {
    const params = useParams();

    const [itemsPerPage, setItemsPerPage] = useState(8);
    const [activePage, setActivePage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [items, setItems] = useState();

    useEffect(() => {
        (async function () {
            const vbs = await request(`Public/Search?keyword=${params.keyword}&lang=en&skip=0&take=${itemsPerPage}&requireTotalCount=true`);
            setTotalCount(vbs.totalCount);
            setItems(vbs.data);
        })();
    }, [])


    // Invoke when user click to request another page.
    const handlePageClick = async (pageNumber) => {
        const newOffset = ((pageNumber - 1) * itemsPerPage) % totalCount;
        const vbs = await request(`Public/Search?keyword=${params.keyword}&lang=en&skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true`);
        setActivePage(pageNumber);
        setTotalCount(vbs.totalCount);
        setItems(vbs.data);
    };
    const handleChangePage = (event) => {
        setItemsPerPage(event.target.value);
    };
    const highlightText = (keyword, mytext) => {
        const chunks = findAll({
            autoEscape: true,
            caseSensitive: false,
            searchWords: [keyword],
            textToHighlight: mytext
        });
        const formattedHTML = chunks.map(chunk => {
            const { end, highlight, start } = chunk;
            const text = mytext.substr(start, end - start);
            if (highlight) {
                return `<mark class="bold">${text}</mark>`;
            } else {
                return text;
            }
        }).join("");
        return parse(formattedHTML);
    }


    return (
        <>
            <div className="breadcrumb">
                <a href="/" className="breadcrumb-item">Home</a>
                <a href="/search" className="breadcrumb-item">Search</a>
            </div>
            <div className="row">
                <div className="main-left col-sm-8 col-12">
                    <div className="f16 mb-2">Keywords: <strong>{params.keyword}</strong> </div>
                    <div className="divider-gray" />
                    <div className="list-tt-c3">
                        {items && items.map((item) =>
                            <div className="item">
                                <div className="date-box">
                                    <Moment format="MM/DD/YYYY">
                                        {item.NgayXuatBan}
                                    </Moment>
                                </div>
                                {item.ID &&
                                    <>
                                        <div className="text-content">
                                            <h2 className="post-title">
                                                <a href={`/topic/${item.ID}`}>{highlightText(params.keyword, item.TieuDe)}</a>
                                            </h2>
                                            <p className="post-desc">
                                                {highlightText(params.keyword, item.MoTa)}
                                            </p>
                                        </div>
                                        <a className="post-image">
                                            <img src={pathImg(item.AnhDaiDienUrl)} alt={pathImg(item.AnhDaiDienUrl)} />
                                        </a>
                                    </>
                                }
                                {item.FileUrl &&
                                    <>
                                        <div className="text-content">
                                            <h2 className="post-title">
                                                <a href={pathFile(item.FileUrl)}>{highlightText(params.keyword, item.TieuDe)}</a>
                                            </h2>
                                            <p className="post-desc">
                                                {highlightText(params.keyword, item.MoTa)}
                                            </p>
                                        </div>
                                        <a className="post-image">
                                            <img src={pathImg(item.AnhDaiDienUrl)} alt={pathImg(item.AnhDaiDienUrl)} />
                                        </a>
                                    </>
                                }
                            </div>
                        )}
                    </div>
                    <div className="pagination-bottom">
                        <div className="page-text">
                            <span className="text-m">Number of rows</span>
                            <div className="select-custom">
                                <select title="Number of rows" name="" id="" className="form-control" onChange={handleChangePage}>
                                    <option value="">8</option>
                                    <option value="">10</option>
                                    <option value="">20</option>
                                </select>
                            </div>
                        </div>
                        <Pagination
                            activePage={activePage}
                            itemClass='page-item'
                            linkClass='page-link'
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={5}
                            onChange={handlePageClick}
                        />
                    </div>
                </div>
                <div className="col-sm-4 col-12">
                    <BoxSideBarEn idChuyenMuc={301} showSame={true} />
                </div>
            </div>
        </>
    );
}
