import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import config from '../../config';
import { pathImg, request, sliceIntoChunks } from '../../utils/api';

export default function BoxPartners({ title="CÁC TRANG THÀNH PHẦN", id, children }) {
  const [partners, setPartners] = useState();
  useEffect(() => {
      (async function () {
          const data = await request(`Public/BoxPartners`);
          setPartners(sliceIntoChunks(data.data, 6));
      })();
  }, [])
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows:false,
  };
  return (
    <>
      <div className="main-title">
          <h2 className="text-m"><a href="#"> {title}</a>
          </h2>
          <ul className="list-cat">
              <li><a href="/trangthanhphan">Xem toàn bộ <span className="isax-arrow-right-21 f16"></span> </a></li>
          </ul>
      </div>
      <Slider className="list-partners tleft" {...settings}>
          {partners && partners.map((items) =>
            <div className="list-item">
              {items.map((item) =>
                <div className="item">
                  <a href={item.URL}>
                    <img src={pathImg(item.AnhBannerUrl)} alt={item.TenBanner} />
                    <span className="text-m">{item.TenBanner}</span>
                  </a>
                </div>
              )}
            </div>
          )}
      </Slider>
    </>
  )
}
