
import React, { useState, useCallback, useRef, useEffect } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import config from '../config';
import { createCustomStore, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxHot from '../components/Home/BoxHot';
import BoxVBPL from '../components/Home/BoxVBPL';
import BoxNDDN from '../components/Home/BoxNDDN';
import BoxNews from '../components/Home/BoxNews';
import BoxMedia from '../components/Home/BoxMedia';
import BoxPartners from '../components/Home/BoxPartners';
import Slider from 'react-slick';
import BoxBanner from '../components/Home/BoxBanner';
import BoxNotification from '../components/Home/BoxNotification';
import BoxVBPLEn from '../components/Home/BoxVBPLEn';
import BoxHotEn from '../components/Home/BoxHotEn';
import { Footer, Header } from '../components';
import HeaderEn from '../components/Layout/HeaderEn';
import FooterEn from '../components/Layout/FooterEn';
import BoxPartnersEn from '../components/Home/BoxPartnersEn';
import BoxMediaEn from '../components/Home/BoxMediaEn';
import { Helmet } from 'react-helmet';

export default function HomeEn() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`MINISTRY OF LABOUR - INVALIDS AND SOCIAL AFFAIRS-Home`}</title>
      </Helmet>
      <HeaderEn
        menuToggleEnabled
      />
      <div className={"main-wrapper"}>
        <div className="container">
          <BoxHotEn />
          <BoxVBPLEn />
          <BoxMediaEn title='MULTIMEDIA' />
          <BoxPartnersEn title='INTERNATIONAL PARTNERS' />
        </div>
      </div>
      <FooterEn />
    </>
  );
}
