import React from 'react';


const Tree = ({ data, renderNode, renderChilds, itemTemplate }) => {
	return (
		<>
			{data.map((item) => 
				{
					const hasChildren = item.children && item.children.length !== 0;
					const branch = renderChilds(item, hasChildren, renderNode, renderChilds);
					const nodeFull = itemTemplate(item, hasChildren, branch);
					return (
						<>
							{nodeFull}
						</>
					)
				}
			)}
		</>
	);
}

export default Tree;