
import React, { useState, useCallback, useRef, useEffect } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import config from '../config';
import { createCustomStore, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function LanhDaoBo() {
  const { t } = useTranslation();
  const [ldthoikys, setLdthoikys] = useState();
  const [lanhdao, setLanhdao] = useState();
  const [tochuc, setTochuc] = useState();
  const params = useParams();
  let id = params.id;
  useEffect(() => {
    (async function () {
      const data = await request(`Public/LanhDaoThoiKy`);
      setLdthoikys(data);
      if (id) {
        const bans = await request(`/Public/ThongTinLanhDaoDetail?ID=${id}`);
        setLanhdao(bans);
      } else {
        const bans = await request(`/Public/CoCauToChucDetail?ID=1574`);
        setTochuc(bans);
      }
    })();
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI-Lãnh đạo bộ`}</title>
      </Helmet>
      <div className='row'>
        <div className='main-left col-md-8 col-sm-8 col-xs-12'>
          <div className="breadcrumb">
            <a href="/" className="breadcrumb-item">Trang chủ</a>
            <a href="/lanhdaobo" className="breadcrumb-item">Lãnh đạo bộ</a>
            {lanhdao && lanhdao.data &&
              <a href={`/lanhdaobo/${lanhdao.data.ID}`} class="breadcrumb-item">{lanhdao.data.HoTenLanhDao}</a>
            }
          </div>
          {tochuc &&
            <div className='lanh-dao-bo'>
              <h2 className="title upper bold center f18 mb-4">
                LÃNH ĐẠO BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI
              </h2>
              {tochuc && tochuc.lanhdaos.map((item, index) => {
                if (item.Hang != 1) {
                  return;
                }
                return (
                  <div className="box-user -top">
                    <div className="avatar img-bg" style={{ backgroundImage: `url("${window.env.REACT_APP_UPLOAD_URL}/${item.AnhDaiDien}")` }}>
                    </div>
                    <div className="user-info">
                      <p>{item.ChucVi}</p>
                      <div><strong>{item.HoTenLanhDao}</strong></div>
                      <p>Điện thoại: {item.SoDienThoai}</p>
                      {item.HoTenThuKy &&
                        <>
                          <div>Thư ký: <a href={`/lanhdaobo/${item.ThuKy}`}><strong>{item.HoTenThuKy}</strong></a></div>
                          <p>Điện thoại: {item.SoDienThoaiThuKy}</p>
                        </>
                      }
                      <a href={`/lanhdaobo/${item.ID}`}>Chi tiết</a>
                    </div>
                  </div>
                )
              })
              }
              <div className="danh-sach-lanh-dao">
                {tochuc && tochuc.lanhdaos.map((item, index) => {
                  if (item.Hang != 2) {
                    return;
                  }
                  return (
                    <div className="item">
                      <div className="box-user">
                        <div className="avatar img-bg" style={{ backgroundImage: `url("${window.env.REACT_APP_UPLOAD_URL}/${item.AnhDaiDien}")` }}>
                        </div>
                        <div className="user-info">
                          <p>{item.ChucVi}</p>
                          <div><strong>{item.HoTenLanhDao}</strong></div>
                          <a href={`/lanhdaobo/${item.ID}`}>Chi tiết</a>
                        </div>
                      </div>
                    </div>
                  )
                })
                }
                {tochuc && tochuc.lanhdaos.map((item, index) => {
                  if (item.Hang != 3) {
                    return;
                  }
                  return (
                    <div className="item">
                      <div className="box-user">
                        <div className="avatar img-bg" style={{ backgroundImage: `url("${window.env.REACT_APP_UPLOAD_URL}/${item.AnhDaiDien}")` }}>
                        </div>
                        <div className="user-info">
                          <p>{item.ChucVi}</p>
                          <div><strong>{item.HoTenLanhDao}</strong></div>
                          <a href={`/lanhdaobo/${item.ID}`}>Chi tiết</a>
                        </div>
                      </div>
                    </div>
                  )
                })
                }
              </div>
            </div>
          }

          {lanhdao && lanhdao.data &&
            <>
              <div className="lanh-dao-bo">
                <div className="box-user -top">
                  <div className="avatar img-bg" style={{ backgroundImage: `url("${window.env.REACT_APP_UPLOAD_URL}/${lanhdao.data.AnhDaiDien}")` }}>
                  </div>
                  <div className="user-info">
                    <p>{lanhdao.data.ChucVi}</p>
                    <div className="f18"><strong>{lanhdao.data.HoTenLanhDao}</strong></div>
                    <p>Điện thoại: {lanhdao.data.SoDienThoai}</p>
                    <br />
                    {lanhdao.data.ThongTinThuKy &&
                      <>
                        <div>Thư ký: <a href={`/lanhdaobo/${lanhdao.data.ThongTinThuKy.ID}`}><strong>{lanhdao.data.ThongTinThuKy.HoTenLanhDao}</strong></a></div>
                        <p>Điện thoại: {lanhdao.data.ThongTinThuKy.SoDienThoai}</p>
                        <br />
                      </>
                    }
                  </div>
                </div>
                <div className="article" dangerouslySetInnerHTML={{ __html: lanhdao.data.TieuSuBanThan }}>
                </div>
              </div>
            </>
          }
        </div>
        <div className='col-sm-4 col-xs-12'>
          <div className='box-sidebar -lanhdao'>
            <div className="head">BỘ TRƯỞNG QUA CÁC THỜI KỲ</div>
            <div className='body py-2 px-3'>
              {ldthoikys && ldthoikys.data.map((item, index) =>
                <div className="box-ld-item -top">
                  <div className="avatar img-bg" style={{ backgroundImage: `url("${window.env.REACT_APP_UPLOAD_URL}/${item.AnhDaiDien}")` }}>
                  </div>
                  <div className="user-info">
                    <p>BỘ TRƯỞNG</p>
                    <a href={`/lanhdaobo/${item.ID}`}><strong>{item.HoTenLanhDao}</strong></a>
                  </div>
                </div>
              )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
