import React, { useState, useCallback, useRef } from 'react';
import {isMobile} from 'react-device-detect';


export default function Breadcrumb({ children}) {
    var flatChildren = children?.flat().filter(function( element ) {
        return element !== undefined;
     });
    var isExpand =flatChildren.length > 4 || isMobile;
    const [expand, setExpand] = useState(!isExpand);
    var firstElement = flatChildren[0];
    var lastElement = flatChildren?.slice(-1).pop();
    return (
        <>
            <div className="breadcrumb">
                {!expand && <>
                    {firstElement}
                    <a href='#' className="breadcrumb-item" onClick={()=>setExpand(true)}>...</a>
                    {lastElement}
                </>}
                {expand && <>
                    {children}
                </>}
            </div>
        </>
    )
}
