
import React, { useState, useCallback, useRef, useEffect } from 'react';

import TreeView from 'devextreme-react/tree-view';
import { listToTree, listToTreeWithRoot, request } from '../../utils/api';
import Tree from './Tree';
import TreeCoCauTemplate from './TreeCoCauTemplate';

export default function TreeCoCauToChuc({ tochucs, className, title }) {
    const itemTemplate = (item, hasChildren, branch) => {
        return (
            <TreeCoCauTemplate item={item} hasChildren={hasChildren} branch={branch} />
        )
    };
    return (
        <>
            <div className={`item ${className}`}>
                <div className="panel">
                    <div className="panel-heading f12">
                        {title}
                    </div>
                    <div className="panel-body">
                        {tochucs &&
                            <ul className='list'>
                                <Tree
                                    key={tochucs.ID}
                                    data={tochucs}
                                    itemTemplate={itemTemplate}
                                    renderChilds={(item, hasChildren, renderNode, renderChilds) => {
                                        if (hasChildren) {
                                            return <ul className="dropdown">
                                                <Tree
                                                    key={item.id}
                                                    data={item.children}
                                                    renderNode={renderNode}
                                                    renderChilds={renderChilds}
                                                    itemTemplate={itemTemplate}
                                                />
                                            </ul>;
                                        }
                                        return null;
                                    }}
                                />
                            </ul>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
