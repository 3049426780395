
import React, { useState, useCallback, useRef, useEffect } from 'react';

import config from '../config';
import { createCustomStore, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import BoxRelate from '../components/Post/BoxRelate';
import { useParams } from 'react-router-dom';
import LayoutHome from '../layouts/LayoutHome';
import BoxNew from '../components/Common/BoxNew';
import BoxNewEn from '../components/Common/BoxNewEn';

export default function ChuyenMucCap1LayoutEn({ chuyenmuc}) {
  
  return (
    <div className="row">
      {chuyenmuc && chuyenmuc.childs.map((item) => 
        <BoxNewEn idChuyenmuc={item.ID} className = "col-md-4 col-12" />
      )}
    </div>
  );
}
