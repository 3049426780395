import React, { useEffect, useState } from 'react';
import { request } from '../../utils/api';
import Moment from 'react-moment';
import Slider from 'react-slick';
export default function BoxSuKienEn({ title = "EVENTS", children }) {
  const [sukiens, setSukiens] = useState();
  useEffect(() => {
    (async function () {
      const data = await request(`Public/BoxSuKien?lang=en`);
      setSukiens(data);
    })();
  }, [])
  var settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false,
  };
  return (
    <>
      {sukiens && sukiens.data.length > 0 &&
        <div className="box-sidebar-hnsk">
          <div className="title"><a href="/event">{title}</a></div>
          <div className="body" style={{ backgroundImage: "url(/assets/images/bg-ht.png)" }}>
            <Slider className="list-hnsk" {...settings}>
              {sukiens && sukiens.data.map((item) =>
                <div className="item">
                  <p className="font-weight-bold ht-title"><a href={`/event/${item.ID}`}>{item.TenHoiThao}</a></p>
                  <div className="info">
                    <span className="icon isax-location1" />
                    <span className="text-m"><span className="fw6">Address:</span> {item.DiaDiemToChuc}</span>
                  </div>
                  <div className="info">
                    <span className="icon isax-clock-11" />
                    <span className="text-m"><span className="fw6">Time:</span>
                      {item.ThoiGianTu &&
                        <Moment locale='en' format=" dddd, MMMM Do, YYYY">
                          {item.ThoiGianTu}
                        </Moment>
                      }
                      {/* -
                    {item.ThoiGianDen && 
                      <Moment format="MM/DD/YYYY HH:mm">
                          {item.ThoiGianDen}
                      </Moment>
                    } */}
                    </span>
                  </div>
                  <div className="info">
                    <span className="icon isax-people1" />
                    <span className="text-m"><span className="fw6">Host:</span> {item.DonViToChucChinh}</span>
                  </div>
                </div>
              )}
            </Slider>
          </div>
        </div>
      }
    </>
  )
}
