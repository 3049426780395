import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import config from '../../config';
import { useAuth } from '../../contexts/auth';
import { pathImg, request, truncatedHtml } from '../../utils/api';
import { Outlet, Link } from "react-router-dom";
import Moment from 'react-moment';
import 'moment/locale/vi';
import parse from 'html-react-parser';
import moment from 'moment';


export default function BoxTinNoiBatEn({ children, id }) {
  const auth = useAuth();
  const [hots, setHots] = useState();
  useEffect(() => {
    (async function () {
      const hots = await request(`Public/BoxTinNoiBatEn?id=${id}`);
      setHots(hots);
    })();
  }, [])
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
  };
  return (
    <>
      <div className="top-news-box">
        <div className="top-news">
          {hots && hots.data.length > 0 && hots.data[0] &&
            <div className="hotnews">
              <a className="post-image">
                <img src={pathImg(hots.data[0].AnhDaiDienUrl)} alt={hots.data[0].AnhDaiDienUrl} />
              </a>
              <h2 className="post-title">
                <a href={`/topic/${hots.data[0].ID}`}>{hots.data[0].TieuDe}</a>
              </h2>
              <p className="post-desc">
                {parse(truncatedHtml(hots.data[0].MoTa))}
              </p>
            </div>
          }
          <div className="list-news-hot">
            {hots && hots.data.map((item, index) => {
              if (index == 0) {
                return;
              }
              return (
                <div className="item">
                  <a href={`/topic/${item.ID}`} className="image">
                    <img src={pathImg(item.AnhDaiDienUrl)} alt={item.AnhDaiDienUrl} />
                  </a>
                  <a className="text-content" href={`/topic/${item.ID}`}>
                    {truncatedHtml(item.TieuDe, 20)}
                  </a>
                </div>
              )
            }
            )}
          </div>
        </div>
      </div>
    </>
  )
}
