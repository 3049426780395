
import React, { useState, useCallback, useRef, useEffect } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import config from '../config';
import { useTranslation } from 'react-i18next';
import BoxSideBar from '../components/Common/BoxSideBar';
import BoxSideBarEn from '../components/Common/BoxSideBarEn';
import BoxSideBarMenu from '../components/Common/BoxSideBarMenu';
import { createCustomStore, request } from '../utils/api';
import { useNavigate, useParams } from 'react-router-dom';

export default function ChucNangNhiemVuEn() {
  const { t } = useTranslation();
  const [post, setPost] = useState();
  const navigate = useNavigate();

  useEffect(() => {
      (async function () {
          const data = await request(`Public/PostDetail?ID=${window.env.REACT_APP_CHUC_NANG_NHIEM_VU_TIENG_ANH}&lang=vi`);
          if (data.data == null) {
              navigate("/");
              return;
          }
      
          setPost(data);
      })();
  }, [])
  const pStyle = {
    display: "block",
    height: "100vh",
    width: "100vw",
  };
  return (
    <>
      <div className="breadcrumb">
        <a href="/" className="breadcrumb-item">Home</a>
        <a href="/dutiesresponsibilities" className="breadcrumb-item">Duties and responsibilities</a>
      </div>
      <div className="row">
        <div className="col-sm-8 col-12">
          <h1>Duties and responsibilities</h1>
          <div className='chucnang-content' dangerouslySetInnerHTML={{ __html: post?.data?.NoiDung }} />
        </div>
        <div className="col-sm-4 col-12">
          {/* <BoxSideBarEn idChuyenMuc={301} showSame={true} /> */}
          <BoxSideBarMenu idMenu={2746} showChild={true} />
        </div>
      </div>
    </>
  );
}
