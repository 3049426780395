import React, { useState, useCallback, useRef, useEffect } from 'react';
import Moment from 'react-moment';

import config from '../../config';
import { pathImg, request } from '../../utils/api';


export default function BoxNewEn({ idChuyenmuc = null, excludes = [], limit = 4, className = "col-md-4 col-12" }) {
    const [news, setNews] = useState();
    useEffect(() => {
        (async function () {
            let queryArr = excludes.join("&exId=");
            const data = await request(`Public/BoxNew?ID=${idChuyenmuc}&exId=${queryArr}&limit=${limit}`);
            setNews(data);
        })();
    }, [])
    return (
        <>
            {news &&
                <div className={className}>
                    <h3 className="main-subtitle">
                        <a className="text-m" href={`/category/${news.data.ID}`}>{news.data.TenChuyenMuc}</a>
                    </h3>
                    <div className="box-news-x mb-5">
                        {news.topposts.length > 0 && <div className="top">
                            <div className="post-image">
                                <img src={pathImg(news.topposts[0].AnhDaiDienUrl)} alt={pathImg(news.topposts[0].AnhDaiDienUrl)} />
                            </div>
                            <div className="text-content">
                                <h3 className="post-title">
                                    <a href={`/topic/${news.topposts[0].ID}`}>{news.topposts[0].TieuDe}</a>

                                </h3>
                                <div className="post-date">
                                    <Moment format="MM/DD/YYYY">
                                        {news.topposts[0].NgayXuatBan}
                                    </Moment>
                                </div>
                            </div>
                        </div>
                        }
                        <div className="list-news">
                            {news.topposts.map((item, index) => {
                                if (index == 0) {
                                    return;
                                }
                                return (
                                    <div className="item">
                                        <div className="post-image">
                                            <img src={pathImg(item.AnhDaiDienUrl)} alt={pathImg(item.AnhDaiDienUrl)} />
                                        </div>
                                        <div className="text-content">
                                            <h3 className="post-title">
                                                <a href={`/topic/${item.ID}`}>{item.TieuDe}</a>
                                            </h3>
                                            <div className="post-date">
                                                <Moment format="MM/DD/YYYY">
                                                    {item.NgayXuatBan}
                                                </Moment>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>
            }
        </>
    )
}
