import React, { useEffect, useState } from 'react';
import { pathFile, pathImg, request } from '../../utils/api';
import Moment from 'react-moment';
import PerfectScrollbar from 'react-perfect-scrollbar'

import config from '../../config';
export default function BoxVBPLEn({ title, limit = 4, children }) {
  const [vanbans, setVanbans] = useState();
  const [banners, setBanners] = useState();
  useEffect(() => {
    (async function () {
      const vbs = await request(`Public/BoxVBPLEn?limit=${limit}`);
      setVanbans(vbs);
      const data = await request(`/Public/BannerByViTri?ID_ViTri=${window.env.REACT_APP_BOXLEGAL_BANNER_TRANG_CHU_TIENG_ANH}`);
      setBanners(data);
    })();
  }, [])
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      let keyword = event.target.value;
      window.open(`/official?search=${keyword}`, '_self');
    }
  }
  return (
    <>
      <div className="main-title">
        <div className="title">
          <span className="icon isax-document-text" />
          <h2 class="text-m"><a href="#">legal documents</a></h2>
        </div>
        <div className="input-search">
          <span className="icon isax-search-normal-11" />
          <input type="text" className="form-control" placeholder="Search..." title="Search..." onKeyPress={handleKeyPress} />
        </div>
      </div>
      <div className="htvb-box mb-4">
        <div className="box-content">
          <div className="tab-content">
            <div className="tab-pane active" id="tabvb1">
              <div className="list-doc-en">
                {vanbans && vanbans.cddhs.map((item) =>
                  <div className="vb-item">
                    <div className="text-content">
                      <h4 className="post-title">
                        <a href={`/official/${item.ID}`}>{item.SoKyHieu} </a>
                      </h4>
                      <div className="post-desc mb-2">
                        {item.TrichYeu}
                      </div>
                      <div className="post-date">
                        {/* {item.NgayBanHanh &&
                          <>
                              <Moment format="MM/DD/YYYY">
                              {item.NgayBanHanh}
                              </Moment>
                          </>
                        } */}
                      </div>
                    </div>
                    <div className="actions">
                      {item.VanBan_Files && item.VanBan_Files.map((item) =>
                        <a href={pathFile(item.File.LinkLuuTru)} className="btn btn-small main py-0">
                          <i className="icon isax-document-download1" />
                          <span className="text-m">Download</span>
                        </a>
                      )}
                      {/* {item.VanBan_Files && item.VanBan_Files.map((item) =>
                        <a href={pathFile(item.File.LinkLuuTru)} className="btn btn-small main py-0">
                          <span className="icon isax-printer1" />
                          <span className="text-m">Print</span>
                        </a>
                      )} */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="box-sidebar">
          {banners && banners.data.map((item, index) =>
            <a href={item.URL} className="banner mb-3">
              <img src={pathImg(item.AnhBannerUrl)} alt={item.AnhBannerUrl} />
            </a>
          )
          }
          {/* <a href="#" className="banner mb-3">
            <img src="assets/uploads/banners/en/1.png" alt="assets/uploads/banners/en/1.png" />
          </a>
          <a href="#" className="banner mb-3">
            <img src="assets/uploads/banners/en/2.png" alt="assets/uploads/banners/en/2.png" />
          </a>
          <a href="#" className="banner mb-3">
            <img src="assets/uploads/banners/en/3.png" alt="assets/uploads/banners/en/3.png" />
          </a>
          <a href="#" className="banner mb-3">
            <img src="assets/uploads/banners/en/4.png" alt="assets/uploads/banners/en/4.png" />
          </a> */}
        </div>
      </div>
    </>
  )
}
