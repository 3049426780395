
import React, { useState, useCallback, useRef, useEffect } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import config from '../config';
import { createCustomStore, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BoxSideBarVBCDDH from '../components/Common/BoxSideBarVBCDDH';
import { useAuth } from '../contexts/auth';
import Pagination from "react-js-pagination";

export default function DanhMucThongTin() {
  const { t } = useTranslation();
  const [danhmucs, setDanhmucs] = useState();
  const [danhmuc, setDanhmuc] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const params = useParams();
  const auth = useAuth();
  const id = params.id;
  useEffect(() => {
    (async function () {
      const data2 = await request(`Public/DanhMucThongTin?skip=0&take=${itemsPerPage}&requireTotalCount=true`);
      setTotalCount(data2.totalCount);
      setDanhmucs(data2);
      if (id) {
        const bans = await request(`/Public/DanhMucThongTinDetail?ID=${id}`);
        setDanhmuc(bans);
      }
    })();
  }, [])
  // Invoke when user click to request another page.
  const handlePageClick = async (pageNumber) => {
    const newOffset = ((pageNumber - 1) * itemsPerPage) % totalCount;
    const data2 = await request(`Public/DanhMucThongTin?skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true`);
    setActivePage(pageNumber);
    setTotalCount(data2.totalCount);
    setDanhmucs(data2);
  };
  const handleChangePage = (event) => {
    setItemsPerPage(20);
  };

  return (
    <>
      <div className="breadcrumb">
        <a href="/" className="breadcrumb-item">Trang chủ</a>
        <a href="/danhmucthongtin" class="breadcrumb-item">Danh mục thông tin</a>
      </div>

      {!danhmuc &&
        <>
          <h1 className="title-main main bold f18 mb-4 text-uppercase">Danh mục thông tin</h1>
          <div className="htvb-box">
            <div className="box-content">
              <div className="list-tt-c3">
                {danhmucs && danhmucs.data.map((item) =>
                  <div className="item">
                    <div className="text-content">
                      <h2 className="post-title">
                        <a href={`/danhmucthongtin/${item.ID}`}>{item.Title}</a>
                      </h2>
                      <p className="post-desc" dangerouslySetInnerHTML={{ __html: item.Description }}>
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="pagination-bottom">
                <div className="page-text">
                  <span className="text-m">Số tin mỗi trang</span>
                  <div className="select-custom">
                    <select title="Số tin mỗi trang" name="" id="" className="form-control" onChange={handleChangePage}>
                      <option value="">8</option>
                      <option value="">10</option>
                      <option value="">20</option>
                    </select>
                  </div>
                </div>

                <Pagination
                  activePage={activePage}
                  itemClass='page-item'
                  linkClass='page-link'
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={5}
                  onChange={handlePageClick}
                />
              </div>
            </div>
          </div>
        </>
      }
      {danhmuc &&
        <>
          <div className="htvb-box">
              <div className="box-content">
                  <div className="article">
                      <h1 className="title-news f26">
                          {danhmuc.data.Title}
                      </h1>
                  <div dangerouslySetInnerHTML={{ __html: danhmuc.data.NoiDung }} />
                </div>
              </div>
          </div>
        </>
      }
    </>
  );
}
