import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import config from '../../config';
import { useAuth } from '../../contexts/auth';
import { pathImg, request } from '../../utils/api';
import { Outlet, Link } from "react-router-dom";

export default function BoxNotification({children }) {
  const auth = useAuth();
  const [danhngons, setDanhngons] = useState();
  const [showLich, setShowLich] = useState();
  const [hots, setHots] = useState();
  useEffect(() => {
    (async function () {
      const hots = await request(`Public/BoxNotification`);
      setHots(hots);
    })();
  }, [])
  let notificationSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows:false,
      vertical:true,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 2000,
      adaptiveHeight: false
  };
  return (
    <>
      <div className="notify-box">
        <a href="/chuyenmuc/27">
          <div className="title">
              <span className="icon isax-notification" />
              <span className="text-m">Thông báo:</span>
          </div>
        </a>
        <Slider className="text-content list-notifications" {...notificationSettings}>
        {hots && hots.datanotifys && hots.datanotifys.map((item) =>
          <div className="item">
            <a href={`/baiviet/${item.ID}`}>{item.TieuDe}</a>
          </div>
        )}
        </Slider>
      </div>
    </>
  )
}
