import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { request } from '../utils/api';

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [menus, setMenus] = useState();
  const [language, setLanguage] = useState();
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
      }
      let lng = window.env.REACT_APP_LANGUAGE;//localStorage.getItem("lng");
      if (!lng) {
        lng = 'vi';
      }
      changeLanguage(lng);
      const menus = await request(`Public/Menu?lang=${lng}`);
      setMenus(menus);
      setLoading(false);
    })();
  }, []);

  const changeLanguage = useCallback(async (lng) => {
    localStorage.setItem('lng', lng);
    i18n.changeLanguage(lng);
    setLanguage(lng);
  }, []);

  const signIn = useCallback(async (email, password, type) => {
    const result = await sendSignInRequest(email, password, type);
    if (result.isOk) {
      setUser(result.data);
      navigate("/");
    }

    return result;
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("user");
    localStorage.removeItem("tokenKey");
    setUser(undefined);
  }, []);


  return (
    <AuthContext.Provider value={{ user, menus, language, signIn, signOut, changeLanguage, loading, setLoading }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
