
import React, { useState, useCallback, useRef } from 'react';
export default function TreeCoCauTemplateEn({ item, hasChildren, branch }) {
  const [show, setShow] = useState(false);
  function handleClick() {
    setShow(!show);
  }
  return (
    <li className={`${hasChildren ? 'has-child' : ''} ${show ? 'open' : ''}`}>
      {hasChildren && <span class="down" onClick={handleClick}></span>}
      <a key={item.id} href={item.IsGroup == 1 ? `#` : `/organizational/${item.ID}`} className={`link`}>{item.TenDonVi}</a>
      {hasChildren && branch}
    </li>
  )
}