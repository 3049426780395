
import React, { useState, useCallback, useRef, useEffect } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import config from '../config';
import { createCustomStore, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function ThoiKyPhatTrien() {
  const { t } = useTranslation();
  const [thoikyphattriens, setThoikyphattriens] = useState();
  const [thoiky, setThoiky] = useState();
  const params = useParams();
  const id = params.id;
  useEffect(() => {
    (async function () {
      const data = await request(`Public/ThoiKyPhatTrien`);
      setThoikyphattriens(data);
      if (id) {
        const bans = await request(`/Public/ThoiKyPhatTrienDetail?ID=${id}`);
        setThoiky(bans);
      }
    })();
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI-Thời kỳ phát triển`}</title>
      </Helmet>
      <div className="breadcrumb">
        <a href="/" className="breadcrumb-item">Trang chủ</a>
        <a href="/thoikyphattrien" className="breadcrumb-item">Thời kỳ phát triển</a>
        {thoiky &&
          <a href={"/thoikyphattrien/${thoiky.data.ID}"} className="breadcrumb-item">{thoiky.data.TenThoiKy}</a>
        }
      </div>
      <div className="row">
        <div className="col-sm-8 col-12">
          {!thoiky &&
            <div className="article">
              <h1 className="title bold center upper f18">CÁC THỜI KỲ PHÁT TRIỂN</h1>
              <p className="bold center f16">Ngành Lao động - Thương binh và Xã hội với chặng đường lịch sử</p>
              <p>Ngay từ những ngày đầu sau khi Cách mạng tháng Tám thành công, Trung ương Đảng - Bác Hồ và Chính phủ đã
                đặc biệt quan tâm lãnh đạo, chỉ đạo lĩnh vực lao động – thương binh và xã hội. Ngày 28/08/1945, Chủ tịch
                Hồ Chí Minh ký Tuyên cáo “về việc thành lập Chính phủ lâm thời Việt Nam Dân chủ Cộng hòa”, trong 13 Bộ
                đầu tiên của Chính phủ nước ta, đã có các Bộ phụ trách công tác lao động – thương binh và xã hội. Cùng
                với quá trình vận động và phát triển của sự nghiệp cách mạng, tháng 2 năm 1987, Bộ Lao động – Thương
                binh và Xã hội được thành lập nhằm kế thừa và phát huy chức năng, nhiệm vụ của các Bộ và cơ quan tiền
                thân trước đó.</p>
              <p>Là một lĩnh vực của đời sống xã hội nước ta, lĩnh vực lao động – thương binh và xã hội là một lĩnh vực
                của đời sống xã hội nước ta, mang những đặc trưng cơ bản: tổng hợp chính trị - kinh tế - xã hội – quốc
                phòng – an ninh; trực tiếp phục vụ hàng triệu người và gia đình có công với Cách mạng và Tổ quốc, hàng
                chục triệu người lao động và hàng triệu người thuộc các đối tượng xã hội khó khăn, yếu thế. Sự vận động
                của quá trình phát triển lao động – thương binh và xã hội trong từng thời kỳ Cách mạng trực tiếp tác
                động tới sự ổn định chính trị, phát triển kinh tế, trật tự - an toàn xã hội, góp phần đắc lực thực hiện
                cương lĩnh, chiến lược xây dựng và bảo vệ đất nước.</p>
              <p className="bold">Các giai đoạn phát triển:</p>
              <ul style={{ marginLeft: '20px' }}>
                {thoikyphattriens && thoikyphattriens.data.map((item, index) =>
                  <li>
                    <a href={`/thoikyphattrien/${item.ID}`}>
                      {item.TenThoiKy}
                    </a>
                  </li>
                )
                }
              </ul>
            </div>
          }
          {thoiky &&
            <>
              <div className="article">
                <div dangerouslySetInnerHTML={{ __html: thoiky.data.NoiDung }} />
              </div>
            </>
          }
        </div>
        <div className="col-sm-4 col-12">
          <div className="box-sidebar -menus">
            <div className="head">CÁC THỜI KỲ PHÁT TRIỂN</div>
            <div className="body">
              <ul className="list-news">
                {thoikyphattriens && thoikyphattriens.data.map((item, index) =>
                  <li className={thoiky && thoiky.data.ID == item.ID ? 'active' : ''}>
                    <a href={`/thoikyphattrien/${item.ID}`} className={`link ${id == item.ID ? 'active' : ''}`}>
                      {item.TenThoiKy}
                    </a>
                  </li>
                )
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
